// @ts-nocheck
const promiseMiddleware = (store) => (next) => (action) => {
  const { promise, types, payload } = action;

  if (!promise) {
    return next(action);
  }

  const [LOADING, RESOLVED, REJECTED] = types;

  next({
    type: LOADING,
  });

  return new Promise((resolve, reject) => {
    promise
      .then((result) => {
        next({
          type: RESOLVED,
          payload: {
            ...result,
            ...payload,
          },
        });

        resolve(result);
      })
      .catch((error) => {
        next({
          type: REJECTED,
          error,
        });

        reject(error);
      });
  });
};

export { promiseMiddleware };
