import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { PeopleAlt as UsersIcon } from "@material-ui/icons";
import { getTime } from "date-fns";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { ErrorSnackbarContext } from "../../../App";
import {
  actionFetchOrderMessages,
  actionPostOrderMessage,
} from "../../Orders/actions";
import styles from "../styles";

interface IOrderMessagesProps extends WithStyles<typeof styles> {
  show: boolean;
  orderId: string;
  onClose: () => void;
  viewOnly?: boolean;
}

const OrderMessagesSchema = yup.object().shape({
  message: yup.string(),
});

function OrderMessages({
  show,
  classes,
  orderId,
  onClose,
  viewOnly = false,
}: IOrderMessagesProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (orderId) {
      dispatch(actionFetchOrderMessages(orderId));
    }
  }, [dispatch, orderId]);

  const messages = useSelector((state: any) => {
    const messages = state.orders.data
      .find((order: any) => order.get("id") === orderId)
      .get("messages");

    if (messages && messages.size) {
      return messages.sortBy((message: any) =>
        getTime(new Date(message.get("sentAt")))
      );
    }

    return messages;
  });

  return (
    <Dialog open={show} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Messages for Order</DialogTitle>

      <Divider />

      <List className={classes.listRoot}>
        {!!messages &&
          messages.map((message: any) => (
            <ListItem alignItems="flex-start" divider dense>
              <ListItemAvatar>
                <Avatar>
                  <UsersIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                secondary={message.get("senderEmail")}
                primary={message.get("message")}
              />
            </ListItem>
          ))}
      </List>

      {!viewOnly && (
        <ErrorSnackbarContext.Consumer>
          {({ toggleAlerts }) => (
            <Formik
              enableReinitialize
              initialValues={{
                message: "",
              }}
              validateOnChange={false}
              validationSchema={OrderMessagesSchema}
              onSubmit={async (values, actions) => {
                await dispatch(actionPostOrderMessage(orderId, values.message))
                  // @ts-ignore
                  .then(
                    () => actions.resetForm(),
                    () => {
                      toggleAlerts(
                        "Error occurred while sending a message.",
                        true
                      );
                    }
                  );
              }}
            >
              {({ errors, values, handleSubmit, handleChange }) => {
                return (
                  <>
                    <DialogContent>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          label="Message"
                          size="small"
                          name="message"
                          variant="outlined"
                          error={!!errors.message}
                          helperText={errors.message}
                          value={values.message}
                          onChange={handleChange}
                          placeholder="Enter message"
                        />
                      </Grid>
                    </DialogContent>

                    <Divider />

                    <DialogActions>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => onClose()}
                      >
                        Close
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit()}
                      >
                        Send
                      </Button>
                    </DialogActions>
                  </>
                );
              }}
            </Formik>
          )}
        </ErrorSnackbarContext.Consumer>
      )}
    </Dialog>
  );
}

export default withStyles(styles)(OrderMessages);
