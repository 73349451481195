import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline, Snackbar, ThemeProvider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";

import { RootRouter } from "../rootRouter";
import { theme } from "../common";

export const ErrorSnackbarContext = React.createContext({
  toggleAlerts: (message: string, error?: boolean) => {},
});

function App() {

  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [showErrorAlert, setErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  function toggleAlerts(message: string, error?: boolean) {
    if (error) {
      setErrorAlert(true);
    } else {
      setSuccessAlert(true);
    }

    setAlertMessage(message);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ErrorSnackbarContext.Provider
          value={{
            toggleAlerts,
          }}
        >
          <RootRouter />
        </ErrorSnackbarContext.Provider>
      </MuiPickersUtilsProvider>

      <Snackbar
        open={showSuccessAlert}
        autoHideDuration={3000}
        onClose={() => setSuccessAlert(false)}
      >
        <Alert onClose={() => setSuccessAlert(false)} severity="success">
          {alertMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={showErrorAlert}
        autoHideDuration={3000}
        onClose={() => setErrorAlert(false)}
      >
        <Alert onClose={() => setErrorAlert(false)} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default App;
