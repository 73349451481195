import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Select,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { isToday, subMonths } from "date-fns";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { ErrorSnackbarContext } from "../../../App";
import { REPORT_TIME, ORDER_CATEGORY } from "../../../common/constants";
import { actionSetDate, actionGenerateSalesForCourierReport } from "../actions";

interface GenerateSalesForCourierReportProps {
  show: boolean;
  onClose: () => void;
}

const GenerateSalesForCourierReportSchema = yup.object().shape({
  startDate: yup.string().nullable().required("Required"),
  endDate: yup.string().nullable().required("Required"),
});

function GenerateSalesForCourierReportProps({
  show,
  onClose,
}: GenerateSalesForCourierReportProps) {
  const dispatch = useDispatch();
  const [endTime, setEndTime] = useState<REPORT_TIME | null>(null);
  const [startTime, setStartTime] = useState<REPORT_TIME | null>(null);

  // A function to reset the start and end times for the dialog upon submission or close
  const resetTime = () => {
    setStartTime(REPORT_TIME.DayEnd);
    setEndTime(REPORT_TIME.Current);
  };

  return (
    <ErrorSnackbarContext.Consumer>
      {({ toggleAlerts }) => (
        <Dialog
          open={show}
          onClose={() => {
            resetTime();
            onClose();
          }}
        >
          <Formik
            key="GenerateSalesForCourierReport"
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={GenerateSalesForCourierReportSchema}
            initialValues={{
              startDate: subMonths(new Date(), 1),
              endDate: new Date(),
            }}
            onSubmit={({ startDate, endDate }) => {
              dispatch(
                actionGenerateSalesForCourierReport({
                  startRange: actionSetDate(startDate, startTime),
                  // @ts-ignore
                  endRange: actionSetDate(endDate, endTime),
                  orderCategory: ORDER_CATEGORY.Courier,
                })
              ) // @ts-ignore
                .then(
                  () => {
                    toggleAlerts("Successfully generated Courier report.");
                    onClose();
                  },
                  () => {
                    toggleAlerts(
                      "Error occurred while creating a Courier report.",
                      true
                    );
                  }
                )
                .finally(() => {
                  resetTime();
                });
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => {
              return (
                <>
                  <DialogTitle>Generate a Courier report</DialogTitle>

                  <Divider />

                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item lg={9}>
                        <KeyboardDatePicker
                          disableFuture
                          disableToolbar
                          fullWidth
                          format="MM/dd/yyyy"
                          variant="inline"
                          inputVariant="outlined"
                          error={!!errors.startDate}
                          helperText={errors.startDate}
                          value={values.startDate}
                          placeholder="Start date"
                          onChange={(_, value) =>
                            setFieldValue("startDate", value)
                          }
                          InputProps={{
                            disableUnderline: true,
                            "aria-label": "filterFrom",
                          }}
                        />
                      </Grid>

                      <Grid item lg={3}>
                        <Select
                          disableUnderline
                          label="Time"
                          native
                          onChange={({ target }) =>
                            setStartTime(target.value as REPORT_TIME)
                          }
                        >
                          <option selected>
                            {isToday(new Date(values.startDate))
                              ? REPORT_TIME.Current
                              : REPORT_TIME.DayEnd}
                          </option>
                          <option>{REPORT_TIME.SixAM}</option>
                          <option>{REPORT_TIME.EightPM}</option>
                        </Select>
                      </Grid>

                      <Grid item lg={9}>
                        <KeyboardDatePicker
                          disableFuture
                          disableToolbar
                          fullWidth
                          format="MM/dd/yyyy"
                          variant="inline"
                          inputVariant="outlined"
                          value={values.endDate}
                          error={!!errors.endDate}
                          helperText={errors.endDate}
                          placeholder="End date"
                          onChange={(_, value) =>
                            setFieldValue("endDate", value)
                          }
                          InputProps={{
                            disableUnderline: true,
                            "aria-label": "filterTo",
                          }}
                        />
                      </Grid>

                      <Grid item lg={3}>
                        <Select
                          disableUnderline
                          label="Time"
                          native
                          onChange={({ target }) =>
                            setEndTime(target.value as REPORT_TIME)
                          }
                        >
                          <option selected>
                            {isToday(new Date(values.endDate))
                              ? REPORT_TIME.Current
                              : REPORT_TIME.DayEnd}
                          </option>
                          <option>{REPORT_TIME.SixAM}</option>
                          <option>{REPORT_TIME.EightPM}</option>
                        </Select>
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <Divider />

                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        resetTime();
                        onClose();
                      }}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Generate
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Dialog>
      )}
    </ErrorSnackbarContext.Consumer>
  );
}

export default GenerateSalesForCourierReportProps;
