import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Formik } from "formik";
import debounce from "lodash.debounce";
import isEmpty from "lodash.isempty";
import React, { forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { ACCOUNTS_TYPE } from "../../common/constants";
import { actionSearchAccounts } from "../../store/Accounts/actions";

interface IAddressProps {
  address?: any;
  account?: any;
  initialValues?: any;
  onSubmit: (values: any, accountId: string, cb?: () => void) => void;
}

const AddressSchema = yup.object().shape({
  address2: yup.string().nullable(),
  buzzerCode: yup.string().nullable(),
  phoneExt: yup.string().nullable(),
  customer: yup.string().required("Required"),
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  postalCode: yup.string().nullable(),
  province: yup.string().required("Required"),
  phone: yup.string().required("Required"),
});

const Address = forwardRef(
  ({ address, account, initialValues, onSubmit }: IAddressProps, ref) => {
    const dispatch = useDispatch();

    const searchedAccounts = useSelector(
      (state: any) => state.accounts.searchedData
    );

    const notDrivers = searchedAccounts.filter(
      (account: any) => !account.get("driver")
    );

    const debounceCustomerSearchInput = debounce((value: string) => {
      dispatch(actionSearchAccounts(value, ACCOUNTS_TYPE.Customer));
    }, 250);

    return (
      <Formik
        key="Address"
        /* @ts-ignore */
        innerRef={ref}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={AddressSchema}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async ({ customer, ...restOfAddress }) => {
          let addressObject = restOfAddress;

          if (!isEmpty(address)) {
            addressObject = {
              id: address.get("id"),
              ...restOfAddress,
            };
          }

          onSubmit(addressObject, customer.id);
        }}
      >
        {({ errors, values, handleChange, setFieldValue }) => (
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Autocomplete
                value={values.customer}
                options={notDrivers.toJS()}
                noOptionsText="Search customer"
                disabled={!isEmpty(account)}
                getOptionLabel={(account: any) =>
                  account
                    ? `${account.firstName} - ${account.lastName} - ${account.email}`
                    : ""
                }
                getOptionSelected={(option: any, value: any) =>
                  option.id === value.id
                }
                onChange={(_, selectedOption: any) =>
                  setFieldValue("customer", selectedOption)
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.customer}
                    helperText={errors.customer}
                    size="small"
                    label="Customer"
                    variant="outlined"
                    placeholder="Customer"
                    onChange={({ target }) =>
                      debounceCustomerSearchInput(target.value)
                    }
                  />
                )}
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                fullWidth
                size="small"
                label="Address 1"
                placeholder="Address 1"
                variant="outlined"
                name="address1"
                error={!!errors.address1}
                helperText={errors.address1}
                value={values.address1}
                onChange={handleChange}
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                fullWidth
                size="small"
                label="Address 2"
                placeholder="Address 2"
                variant="outlined"
                name="address2"
                value={values.address2}
                onChange={handleChange}
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                fullWidth
                label="City"
                size="small"
                placeholder="City"
                variant="outlined"
                name="city"
                value={values.city}
                error={!!errors.city}
                helperText={errors.city}
                onChange={handleChange}
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                fullWidth
                size="small"
                label="Postal Code"
                variant="outlined"
                name="postalCode"
                placeholder="Postal Code (Optional)"
                inputProps={{
                  maxLength: 6,
                }}
                error={!!errors.postalCode}
                helperText={errors.postalCode}
                value={values.postalCode}
                onChange={handleChange}
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                fullWidth
                size="small"
                name="province"
                label="Province"
                variant="outlined"
                placeholder="Province"
                error={!!errors.province}
                helperText={errors.province}
                value={values.province}
                onChange={handleChange}
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                fullWidth
                size="small"
                label="Phone"
                placeholder="Phone"
                variant="outlined"
                name="phone"
                error={!!errors.phone}
                helperText={errors.phone}
                value={values.phone}
                onChange={handleChange}
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                fullWidth
                label="Phone Ext"
                size="small"
                placeholder="Phone Ext"
                variant="outlined"
                name="phoneExt"
                value={values.phoneExt}
                onChange={handleChange}
              />
            </Grid>

            <Grid item lg={12}>
              <TextField
                fullWidth
                label="Buzzer code"
                size="small"
                placeholder="Buzzer code"
                variant="outlined"
                name="buzzerCode"
                value={values.buzzerCode}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    );
  }
);

export default Address;