import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    actionsCell: {
      alignItems: "flex-end",
      justifyContent: "center",
      flexDirection: "column",
      paddingRight: theme.spacing(2),
    },
    clearButton: {
      marginLeft: theme.spacing(1),
    },
    wrapper: {
      display: "grid",
      gridTemplateRows: "2fr 2fr",
      gridGap: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    blockTableCell: {
      alignItems: "flex-start",
    },
    listRoot: {
      backgroundColor: theme.palette.background.paper,
    },
    selectCheckbox: {
      padding: 0,
    },
    selectCheckboxCell: {
      paddingTop: "12px",
      alignItems: "center",
    },
    cardContent: {
      padding: "0 !important",
      alignItems: "center",
      width: "100%",
      verticalAlign: 'middle'
    },
    cardContentDivider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divider: {
      height: 28,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    ordersDeliveredCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    ordersDeliveredCardHeader: {
      backgroundColor: "white",
    },
    currentlyDispatchedCard: {
      display: "block",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    currentlyDispatchedCardHeader: {
      backgroundColor: "white",
    },
    reassignDialog: {
      width: "350px",
    },
    driversCard: {
      boxShadow: "none",
      marginBottom: theme.spacing(2),
    },
    driversCardHeader: {
      backgroundColor: "white",
    },
    availabilityToggle: {
      marginRight: "10px",
    },
  });

export default styles;
