import { Card, CardHeader, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import styles from "./styles";

interface EmptyTableProps extends WithStyles<typeof styles> {}

const EmptyTable = React.memo(({ classes }: EmptyTableProps) => (
  <Card square className={classes.emptyTableReportCard}>
    <CardHeader
      subheader={"No records"}
      className={classes.emptyTableReportCardHeader}
    />
  </Card>
));

export default withStyles(styles)(EmptyTable);
