import axios from "axios";
import applyCaseConverters from "axios-case-converter";

// Utilities
import { version } from "../package.json";

export const apiInstance = applyCaseConverters(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("x-access-token")}`,
    },
  })
);

apiInstance.interceptors.response.use(undefined, (error: Error) => {
  if (
    error.message.includes("401") &&
    !window.location.href.includes("signin")
  ) {
    localStorage.removeItem("x-access-token");
    window.location.href = "/signin";
    // window.history.replaceState(null, "", "/signin");
  }

  return Promise.reject(error);
});

export default {
  apiURL: process.env.REACT_APP_API_URL,
  tokenTimeout: 12 * 60 * 60 * 1000, // 12 hours
  version,
};
