import { List, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

import {
  CREATE_ACCOUNT_START_TYPE,
  CREATE_ACCOUNT_SUCCESSFUL_TYPE,
  CREATE_ACCOUNT_FAILED_TYPE,
  DEACTIVATE_ACCOUNT_START_TYPE,
  DEACTIVATE_ACCOUNT_SUCCESSFUL_TYPE,
  DEACTIVATE_ACCOUNT_FAILED_TYPE,
  FETCH_ACCOUNTS_START_TYPE,
  FETCH_ACCOUNTS_SUCCESSFUL_TYPE,
  FETCH_ACCOUNTS_FAILED_TYPE,
  SEARCH_ACCOUNTS_START_TYPE,
  SEARCH_ACCOUNTS_FAILED_TYPE,
  SEARCH_ACCOUNTS_SUCCESSFUL_TYPE,
  CLEAR_SEARCHED_ACCOUNTS_TYPE,
  EDIT_ACCOUNT_START_TYPE,
  EDIT_ACCOUNT_SUCCESSFUL_TYPE,
  EDIT_ACCOUNT_FAILED_TYPE,
  SET_ACCOUNT_NOTES,
} from "./actions";
import type { Account } from "./types";

import {
  ADD_ADDRESS_START_TYPE,
  ADD_ADDRESS_SUCCESSFUL_TYPE,
  ADD_ADDRESS_FAILED_TYPE,
  EDIT_ADDRESS_START_TYPE,
  EDIT_ADDRESS_SUCCESSFUL_TYPE,
  EDIT_ADDRESS_FAILED_TYPE,
} from "../Addresses/actions";

interface AccountsState {
  data: List<Account>;
  accountsDataCursor: number | null;
  accountsDataRemaining: number | null;

  searchedData: List<Account>;

  isAddingAddress: boolean;
  isDeactivatingAccount: boolean;
  isEditingAccount: boolean;
  isCreatingAccount: boolean;
  isFetchingAccounts: boolean;
  isSearchingAccounts: boolean;
}

const initialAccountsState: AccountsState = {
  data: List(),
  accountsDataCursor: null,
  accountsDataRemaining: null,

  searchedData: List(),

  isAddingAddress: false,
  isDeactivatingAccount: false,
  isEditingAccount: false,
  isCreatingAccount: false,
  isFetchingAccounts: false,
  isSearchingAccounts: false,
};

function reducer(state = initialAccountsState, { type, payload }: any) {
  switch (type) {
    case FETCH_ACCOUNTS_START_TYPE: {
      return {
        ...state,
        isFetchingAccounts: true,
      };
    }

    case FETCH_ACCOUNTS_SUCCESSFUL_TYPE: {
      const { data: responseData, accountsType } = payload;
      const { accounts: responseAccounts, cursor, remaining } = responseData;

      if (!isEmpty(responseAccounts)) {
        let accountsList = state.data;

        if (accountsList.size) {
          responseAccounts.forEach((responseAccount: any) => {
            const responseAccountMap = fromJS(responseAccount);

            const existingAccountIndex = accountsList.findIndex(
              (existingAccount: any) =>
                existingAccount.get("id") === responseAccountMap.get("id")
            );

            if (existingAccountIndex !== -1) {
              accountsList = accountsList.update(
                existingAccountIndex,
                (existingAccount: any) =>
                  existingAccount.merge(responseAccountMap)
              );
            } else {
              accountsList = accountsList.push(responseAccountMap);
            }
          });

          state.data = accountsList;
        } else {
          state.data = fromJS(responseAccounts);
        }
      }

      if (!accountsType) {
        return {
          ...state,
          accountsDataCursor: cursor,
          accountsDataRemaining: remaining,
          isFetchingAccounts: false,
        };
      }

      return {
        ...state,
        isFetchingAccounts: false,
      };
    }

    case FETCH_ACCOUNTS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingAccounts: false,
      };
    }

    case SEARCH_ACCOUNTS_START_TYPE: {
      return {
        ...state,
        isSearchingAccounts: true,
      };
    }

    case SEARCH_ACCOUNTS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.searchedData = fromJS(responseData);
      }

      return {
        ...state,
        isSearchingAccounts: false,
      };
    }

    case SEARCH_ACCOUNTS_FAILED_TYPE: {
      return {
        ...state,
        isSearchingAccounts: false,
      };
    }

    case CLEAR_SEARCHED_ACCOUNTS_TYPE: {
      return {
        ...state,
        searchedData: List(),
      };
    }

    case CREATE_ACCOUNT_START_TYPE: {
      return {
        ...state,
        isCreatingAccount: true,
      };
    }

    case CREATE_ACCOUNT_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.data = state.data.unshift(fromJS(responseData));
      }

      return {
        ...state,
        isCreatingAccount: false,
      };
    }

    case CREATE_ACCOUNT_FAILED_TYPE: {
      return {
        ...state,
        isCreatingAccount: false,
      };
    }

    case DEACTIVATE_ACCOUNT_START_TYPE: {
      return {
        ...state,
        isDeactivatingAccount: true,
      };
    }

    case DEACTIVATE_ACCOUNT_FAILED_TYPE: {
      return {
        ...state,
        isDeactivatingAccount: false,
      };
    }

    case DEACTIVATE_ACCOUNT_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;
      const { accountId } = responseData;

      state.data = state.data.filter((a: any) => a.get("id") !== accountId);
      state.searchedData = state.searchedData.filter(
        (a: any) => a.get("id") !== accountId
      );

      return {
        ...state,
        isDeactivatingAccount: false,
      };
    }

    case EDIT_ACCOUNT_START_TYPE: {
      return {
        ...state,
        isEditingAccount: true,
      };
    }

    case EDIT_ACCOUNT_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const responseAccountMap = fromJS(responseData);
        let accountsList, accountIndex;

        // Updated account could be in both fetched and searched lists.
        // So, we need to update both.

        // First, update the fetched ones
        accountsList = state.data;

        accountIndex = accountsList.findIndex(
          (account: any) => account.get("id") === responseAccountMap.get("id")
        );

        if (accountIndex !== -1) {
          accountsList = accountsList.update(accountIndex, (account: any) =>
            account.merge(responseAccountMap)
          );
        }

        state.data = accountsList;

        // Then update the searched ones
        accountsList = state.searchedData;

        accountIndex = accountsList.findIndex(
          (account: any) => account.get("id") === responseAccountMap.get("id")
        );

        if (accountIndex !== -1) {
          accountsList = accountsList.update(accountIndex, (account: any) =>
            account.merge(responseAccountMap)
          );
        }

        state.searchedData = accountsList;
      }

      return {
        ...state,
        isEditingAccount: false,
      };
    }

    case EDIT_ACCOUNT_FAILED_TYPE: {
      return {
        ...state,
        isEditingAccount: false,
      };
    }

    case ADD_ADDRESS_START_TYPE: {
      return {
        ...state,
        isAddingAddress: true,
      };
    }

    case ADD_ADDRESS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const responseAddressMap = fromJS(responseData[0]);
        let accountsList, accountIndex;

        // Added address could be in both fetched and searched lists.
        // So, we need to update both.

        // First, update the fetched ones
        accountsList = state.data;

        accountIndex = accountsList.findIndex(
          (account: any) =>
            account.get("id") === responseAddressMap.get("account")
        );

        if (accountIndex !== -1) {
          accountsList = accountsList.update(accountIndex, (account: any) =>
            account.update("addresses", (addresses: any) =>
              !addresses
                ? List().push(responseAddressMap)
                : addresses.push(responseAddressMap)
            )
          );
        }

        state.data = accountsList;

        // Then update the searched ones
        accountsList = state.searchedData;

        accountIndex = accountsList.findIndex(
          (account: any) =>
            account.get("id") === responseAddressMap.get("account")
        );

        if (accountIndex !== -1) {
          accountsList = accountsList.update(accountIndex, (account: any) =>
            account.update("addresses", (addresses: any) =>
              !addresses
                ? List().push(responseAddressMap)
                : addresses.push(responseAddressMap)
            )
          );
        }

        state.searchedData = accountsList;
      }

      return {
        ...state,
        isAddingAddress: false,
      };
    }

    case ADD_ADDRESS_FAILED_TYPE: {
      return {
        ...state,
        isAddingAddress: false,
      };
    }

    case EDIT_ADDRESS_START_TYPE: {
      return {
        ...state,
        isEditingAddress: true,
      };
    }

    case EDIT_ADDRESS_SUCCESSFUL_TYPE: {
      const { data: responseData, accountId } = payload;

      if (!isEmpty(responseData)) {
        const responseAddressMap = fromJS(responseData[0]);
        const responseAddressId = responseAddressMap.get("id");
        let accountsList, accountIndex;

        // Added address could be in both fetched and searched lists.
        // So, we need to update both.

        // First, update the fetched ones
        accountsList = state.data;

        accountIndex = accountsList.findIndex(
          (account: any) => account.get("id") === accountId
        );

        if (accountIndex !== -1) {
          accountsList = accountsList.updateIn(
            [accountIndex, "addresses"],
            (addresses: any) => {
              const addressIndex = addresses.findIndex(
                (address: any) => address.get("id") === responseAddressId
              );

              return addresses.update(addressIndex, (address: any) =>
                address.merge(responseAddressMap)
              );
            }
          );
        }

        state.data = accountsList;

        // Then update the searched ones
        accountsList = state.searchedData;

        accountIndex = accountsList.findIndex(
          (account: any) => account.get("id") === accountId
        );

        if (accountIndex !== -1) {
          accountsList = accountsList.updateIn(
            [accountIndex, "addresses"],
            (addresses: any) => {
              const addressIndex = addresses.findIndex(
                (address: any) => address.get("id") === responseAddressId
              );

              return addresses.update(addressIndex, (address: any) =>
                address.merge(responseAddressMap)
              );
            }
          );
        }

        state.searchedData = accountsList;
      }

      return {
        ...state,
        isEditingAddress: false,
      };
    }

    case EDIT_ADDRESS_FAILED_TYPE: {
      return {
        ...state,
        isEditingAddress: false,
      };
    }

    // Set account notes (are changed by themselves in create/edit order)
    case SET_ACCOUNT_NOTES: {
      const { accountId, notes } = payload;

      let accountsList = state.data;
      const accountIndex = accountsList.findIndex(
        (account: any) => account.get("id") === accountId
      );
      if (accountIndex === -1) return { ...state };

      accountsList = accountsList.update(accountIndex, (account: any) =>
        account.set("notes", notes)
      );

      state.data = accountsList;

      return {
        ...state,
      };
    }

    default:
      return state;
  }
}

export default reducer;
