import { List, fromJS } from "immutable";
import isEmpty from "lodash.isempty";
import fileDownload from "js-file-download";

import {
  CREATE_REPORT_START_TYPE,
  CREATE_REPORT_SUCCESSFUL_TYPE,
  CREATE_REPORT_FAILED_TYPE,
  DOWNLOAD_REPORTS_START_TYPE,
  DOWNLOAD_REPORTS_SUCCESSFUL_TYPE,
  DOWNLOAD_REPORTS_FAILED_TYPE,
  FETCH_REPORTS_START_TYPE,
  FETCH_REPORTS_SUCCESSFUL_TYPE,
  FETCH_REPORTS_FAILED_TYPE,
  DELETE_REPORT_START_TYPE,
  DELETE_REPORT_SUCCESSFUL_TYPE,
  DELETE_REPORT_FAILED_TYPE,
  GENERATE_EXTERNAL_ORDER_REPORT_SUCCESSFUL_TYPE,
  GENERATE_EXTERNAL_ORDER_REPORT_START_TYPE,
  GENERATE_EXTERNAL_ORDER_REPORT_FAILED_TYPE,
} from "./actions";

interface ReportsState {
  data: List<any>;
  isCreatingReport: boolean;
  isFetchingReports: boolean;
  isDownloadingReports: boolean;
}

const initialReportsState: ReportsState = {
  data: List<any>(),
  isCreatingReport: false,
  isFetchingReports: false,
  isDownloadingReports: false,
};

// @ts-ignore
function reducer(state = initialReportsState, { type, payload }) {
  switch (type) {
    case FETCH_REPORTS_START_TYPE: {
      return {
        ...state,
        isFetchingReports: true,
      };
    }

    case FETCH_REPORTS_SUCCESSFUL_TYPE: {
      const { data: responseData, type } = payload;

      if (!isEmpty(responseData)) {
        const updatedResponse = responseData.map((report: any) => ({
          ...report,
          type,
        }));

        state.data = List(fromJS(updatedResponse));
      }

      return {
        ...state,
        isFetchingReports: false,
      };
    }

    case FETCH_REPORTS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingReports: false,
      };
    }

    case CREATE_REPORT_START_TYPE: {
      return {
        ...state,
        isCreatingReport: true,
      };
    }

    case CREATE_REPORT_SUCCESSFUL_TYPE: {
      const { data: responseData, type } = payload;

      if (!isEmpty(responseData)) {
        const updatedResponse = {
          ...responseData,
          type,
        };

        state.data = state.data.push(fromJS(updatedResponse));
      }

      return {
        ...state,
        isCreatingReport: false,
      };
    }

    case CREATE_REPORT_FAILED_TYPE: {
      return {
        ...state,
        isCreatingReport: false,
      };
    }

    case DELETE_REPORT_START_TYPE: {
      return {
        ...state,
        isDeletingReport: true,
      };
    }

    case DELETE_REPORT_SUCCESSFUL_TYPE: {
      let reportsList = state.data;

      const { reportId } = payload;

      reportsList = reportsList.filter(
        (report: any) => report.get("id") !== reportId
      );

      state.data = reportsList;
      return {
        ...state,
        isDeletingReport: false,
      };
    }

    case DELETE_REPORT_FAILED_TYPE: {
      return {
        ...state,
        isDeletingReport: false,
      };
    }

    case DOWNLOAD_REPORTS_START_TYPE: {
      return {
        ...state,
        isDownloadingReport: true,
      };
    }

    case DOWNLOAD_REPORTS_SUCCESSFUL_TYPE: {
      const { data: responseData, ...rest } = payload;

      if (!isEmpty(responseData)) {
        const {
          headers: { contentDisposition },
        } = rest;
        const fileName = contentDisposition.match('"(.*?)"')[1];

        fileDownload(responseData, fileName);
      }

      return {
        ...state,
        isDownloadingReport: false,
      };
    }

    case DOWNLOAD_REPORTS_FAILED_TYPE: {
      return {
        ...state,
        isDownloadingReport: false,
      };
    }

    case GENERATE_EXTERNAL_ORDER_REPORT_START_TYPE: {
      return {
        ...state,
        isGeneratingExternalOrderReport: true,
      };
    }

    case GENERATE_EXTERNAL_ORDER_REPORT_SUCCESSFUL_TYPE: {
      const { data: responseData, ...rest } = payload;

      if (!isEmpty(responseData)) {
        const {
          headers: { contentDisposition },
        } = rest;
        const fileName = contentDisposition.match('"(.*?)"')[1];

        fileDownload(responseData, fileName);
      }

      return {
        ...state,
        isGeneratingExternalOrderReport: false,
      };
    }

    case GENERATE_EXTERNAL_ORDER_REPORT_FAILED_TYPE: {
      return {
        ...state,
        isGeneratingExternalOrderReport: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
