import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { isToday, subMonths } from "date-fns";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { ErrorSnackbarContext } from "../../../App";
import { REPORT_TIME } from "../../../common/constants";
import { actionCreateReport, actionSetDate, ReportType } from "../actions";

interface AddSalesReportProps {
  show: boolean;
  onClose: () => void;
}

const AddSalesReportSchema = yup.object().shape({
  startDate: yup.string().nullable().required("Required"),
  endDate: yup.string().nullable().required("Required"),
  city: yup.string().nullable(),
});

function AddSalesReport({ show, onClose }: AddSalesReportProps) {
  const dispatch = useDispatch();
  const [endTime, setEndTime] = useState<REPORT_TIME | null>(null);
  const [startTime, setStartTime] = useState<REPORT_TIME | null>(null);

  // A function to reset the start and end times for the dialog upon submission or close
  const resetTime = () => {
    setStartTime(REPORT_TIME.DayEnd);
    setEndTime(REPORT_TIME.Current);
  };

  return (
    <ErrorSnackbarContext.Consumer>
      {({ toggleAlerts }) => (
        <Dialog
          open={show}
          onClose={() => {
            resetTime();
            onClose();
          }}
        >
          <Formik
            key="AddSalesReport"
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={AddSalesReportSchema}
            initialValues={{
              startDate: subMonths(new Date(), 1),
              endDate: new Date(),
              city: "",
            }}
            onSubmit={({ startDate, endDate, city }) => {
              dispatch(
                actionCreateReport(ReportType.Sales, {
                  startRange: actionSetDate(startDate, startTime),
                  // @ts-ignore
                  endRange: actionSetDate(endDate, endTime),
                  city: city,
                })
              ) // @ts-ignore
                .then(
                  () => {
                    toggleAlerts("Successfully created a Sales report.");
                    setStartTime(REPORT_TIME.DayEnd);
                    setEndTime(REPORT_TIME.Current);
                    onClose();
                  },
                  () => {
                    toggleAlerts(
                      "Error occurred while creating a Sales report.",
                      true
                    );
                    setStartTime(REPORT_TIME.DayEnd);
                    setEndTime(REPORT_TIME.Current);
                  }
                )
                .finally(() => {
                  resetTime();
                });
            }}
          >
            {({
              errors,
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => {
              return (
                <>
                  <DialogTitle>Add a sales report</DialogTitle>

                  <Divider />

                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item lg={9}>
                        <KeyboardDatePicker
                          disableFuture
                          disableToolbar
                          fullWidth
                          format="MM/dd/yyyy"
                          variant="inline"
                          inputVariant="outlined"
                          error={!!errors.startDate}
                          helperText={errors.startDate}
                          value={values.startDate}
                          placeholder="Start date"
                          onChange={(_, value) =>
                            setFieldValue("startDate", value)
                          }
                          InputProps={{
                            disableUnderline: true,
                            "aria-label": "filterFrom",
                          }}
                        />
                      </Grid>

                      <Grid item lg={3}>
                        <Select
                          disableUnderline
                          label="Time"
                          native
                          onChange={({ target }) =>
                            setStartTime(target.value as REPORT_TIME)
                          }
                        >
                          {isToday(new Date(values.startDate)) && (
                            <option selected>{REPORT_TIME.Current}</option>
                          )}
                          {!isToday(new Date(values.startDate)) && (
                            <option selected>{REPORT_TIME.DayEnd}</option>
                          )}
                          <option>{REPORT_TIME.SixAM}</option>
                          <option>{REPORT_TIME.EightPM}</option>
                        </Select>
                      </Grid>

                      <Grid item lg={9}>
                        <KeyboardDatePicker
                          disableFuture
                          disableToolbar
                          fullWidth
                          format="MM/dd/yyyy"
                          variant="inline"
                          inputVariant="outlined"
                          value={values.endDate}
                          error={!!errors.endDate}
                          helperText={errors.endDate}
                          placeholder="End date"
                          onChange={(_, value) =>
                            setFieldValue("endDate", value)
                          }
                          InputProps={{
                            disableUnderline: true,
                            "aria-label": "filterFrom",
                          }}
                        />
                      </Grid>

                      <Grid item lg={3}>
                        <Select
                          disableUnderline
                          label="Time"
                          native
                          onChange={({ target }) =>
                            setEndTime(target.value as REPORT_TIME)
                          }
                        >
                          {isToday(new Date(values.endDate)) && (
                            <option selected>{REPORT_TIME.Current}</option>
                          )}
                          {!isToday(new Date(values.endDate)) && (
                            <option selected>{REPORT_TIME.DayEnd}</option>
                          )}
                          <option>{REPORT_TIME.SixAM}</option>
                          <option>{REPORT_TIME.EightPM}</option>
                        </Select>
                      </Grid>
                      <Grid item lg={4}>
                        <Autocomplete
                          disableClearable
                          value={values.city}
                          options={[
                            "All",
                            "Kitchener",
                            "Waterloo",
                            "Guelph",
                            "Cambridge",
                          ]}
                          getOptionLabel={(type: string) => type}
                          onChange={(_, selectedOption: string) =>
                            setFieldValue("city", selectedOption)
                          }
                          getOptionSelected={(option: any, value: any) =>
                            option === value
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              disable
                              fullWidth
                              error={!!errors.city}
                              helperText={errors.city}
                              size="small"
                              label="City"
                              variant="outlined"
                              placeholder="City"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <Divider />

                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        resetTime();
                        onClose();
                      }}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Dialog>
      )}
    </ErrorSnackbarContext.Consumer>
  );
}

export default AddSalesReport;
