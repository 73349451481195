enum ORDER_TYPE {
  Recent = "recent",
  Past = "past",
  Completed = "completed",
}

enum SORT_ORDER_TYPE {
  ASC = "asc",
  DESC = "desc",
}

enum ORDER_FORM_TYPE {
  Create = "create",
  Edit = "edit",
  Reorder = "reorder",
}

enum ACCOUNTS_TYPE {
  Admin = "Admin",
  Driver = "Driver",
  Customer = "Customer",
}

enum REPORT_TIME {
  Current = "Current time",
  SixAM = "6 AM",
  EightPM = "8 PM",
  DayEnd = "End of day",
}

enum COMPANY {
  Lcbo = "lcbo",
  Tbs = "the-beer-store",
}

enum ORDER_CATEGORY {
  Courier = "courier",
}

export {
  ACCOUNTS_TYPE,
  ORDER_TYPE,
  ORDER_FORM_TYPE,
  SORT_ORDER_TYPE,
  REPORT_TIME,
  COMPANY,
  ORDER_CATEGORY,
};
