import { List, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

import {
  FETCH_PAYMENT_METHODS_START_TYPE,
  FETCH_PAYMENT_METHODS_SUCCESSFUL_TYPE,
  FETCH_PAYMENT_METHODS_FAILED_TYPE,
} from "./actions";

const initialPaymentMethodsState = {
  data: List<any>(),
  isFetchingPaymentMethods: false,
};

// @ts-ignore
function paymentMethods(state = initialPaymentMethodsState, { type, payload }) {
  switch (type) {
    case FETCH_PAYMENT_METHODS_START_TYPE: {
      return {
        ...state,
        isFetchingPaymentMethods: true,
      };
    }

    case FETCH_PAYMENT_METHODS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.data = fromJS(responseData);
      }

      return {
        ...state,
      };
    }

    case FETCH_PAYMENT_METHODS_FAILED_TYPE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

export default paymentMethods;
