import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Select
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { isToday, subMonths } from "date-fns";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { ErrorSnackbarContext } from "../../../App";
import  { REPORT_TIME } from '../../../common/constants';
import { actionCreateReport, actionSetDate, ReportType } from "../actions";

interface AddOrderPlacedReportProps {
  show: boolean;
  onClose: () => void;
}

const AddOrderPlacedReportSchema = yup.object().shape({
  startDate: yup.string().nullable().required("Required"),
  endDate: yup.string().nullable().required("Required"),
});

function AddOrderPlacedReport({ show, onClose }: AddOrderPlacedReportProps) {
  const dispatch = useDispatch();
  const [endTime, setEndTime] = useState<REPORT_TIME | null>(null);
  const [startTime, setStartTime] = useState<REPORT_TIME | null>(null);

  return (
    <Dialog open={show} onClose={onClose}>
      <ErrorSnackbarContext.Consumer>
        {({ toggleAlerts }) => (
          <Formik
            key="AddOrderPlacedReport"
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={AddOrderPlacedReportSchema}
            initialValues={{
              startDate: subMonths(new Date(), 1),
              endDate: new Date(),
            }}
            onSubmit={({ startDate, endDate }) => {
              dispatch(
                actionCreateReport(ReportType.Order_Placed, {
                  startRange: actionSetDate(startDate, startTime),
                  // @ts-ignore
                  endRange: actionSetDate(endDate, endTime),
                })
                // @ts-ignore
              ).then(
                () => {
                  toggleAlerts("Successfully created an Order-Placed report.");
                  onClose();
                },
                () => {
                  toggleAlerts(
                    "Error occurred while creating an Order-Placed report.",
                    true
                  );
                }
              );
            }}
          >
            {({ errors, values, handleSubmit, setFieldValue }) => {
              return (
                <>
                  <DialogTitle>Add order placed report</DialogTitle>

                  <Divider />

                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item lg={9}>
                        <KeyboardDatePicker
                          disableFuture
                          disableToolbar
                          fullWidth
                          format="MM/dd/yyyy"
                          variant="inline"
                          inputVariant="outlined"
                          error={!!errors.startDate}
                          helperText={errors.startDate}
                          value={values.startDate}
                          placeholder="Start date"
                          onChange={(_, value) =>
                            setFieldValue("startDate", value)
                          }
                          InputProps={{
                            disableUnderline: true,
                            "aria-label": "filterFrom",
                          }}
                        />
                      </Grid>

                      <Grid item lg={3}>
                        <Select
                          disableUnderline
                          label="Time"
                          native
                          onChange={({ target }) => setStartTime(target.value as REPORT_TIME)}
                        >
                          {isToday(new Date(values.startDate)) &&
                            <option selected>{REPORT_TIME.Current}</option>
                          }
                          {!isToday(new Date(values.startDate)) &&
                            <option selected>{REPORT_TIME.DayEnd}</option>
                          }
                          <option>{REPORT_TIME.SixAM}</option>
                          <option>{REPORT_TIME.EightPM}</option>
                        </Select>
                      </Grid>

                      <Grid item lg={9}>
                        <KeyboardDatePicker
                          disableFuture
                          disableToolbar
                          fullWidth
                          format="MM/dd/yyyy"
                          variant="inline"
                          inputVariant="outlined"
                          value={values.endDate}
                          error={!!errors.endDate}
                          helperText={errors.endDate}
                          placeholder="End date"
                          onChange={(_, value) =>
                            setFieldValue("endDate", value)
                          }
                          InputProps={{
                            disableUnderline: true,
                            "aria-label": "filterFrom",
                          }}
                        />
                      </Grid>

                      <Grid item lg={3}>
                        <Select
                          disableUnderline
                          label="Time"
                          native
                          onChange={({ target }) => setEndTime(target.value as REPORT_TIME)}
                        >
                          {isToday(new Date(values.endDate)) &&
                            <option selected>{REPORT_TIME.Current}</option>
                          }
                          {!isToday(new Date(values.endDate)) &&
                            <option selected>{REPORT_TIME.DayEnd}</option>
                          }
                          <option>{REPORT_TIME.SixAM}</option>
                          <option>{REPORT_TIME.EightPM}</option>
                        </Select>
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <Divider />

                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onClose()}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        )}
      </ErrorSnackbarContext.Consumer>
    </Dialog>
  );
}

export default AddOrderPlacedReport;
