const RecentOrdersTableColumns = [
  {
    dataKey: "selected",
    label: "",
    width: 50,
  },
  {
    dataKey: "number",
    label: "Order #",
    width: 70,
  },
  {
    dataKey: "status",
    label: "Status",
    width: 150,
  },
  {
    dataKey: "products",
    label: "Products",
    width: 280,
  },
  {
    dataKey: "customer",
    label: "Customer",
    width: 200,
  },
  {
    dataKey: "address",
    label: "Address",
    width: 300,
  },
  {
    dataKey: "receivedAt",
    label: "Received",
    width: 180,
  },
  {
    dataKey: "isDispatched",
    label: "Dispatch",
    width: 150,
  },
  {
    dataKey: "actions",
    label: "Actions",
    width: 220,
  },
  // {
  //   dataKey: "recalculate",
  //   label: "",
  //   width: 130,
  // },
  // {
  //   dataKey: "resendEmails",
  //   label: "",
  //   width: 130,
  // },
  // {
  //   dataKey: "pos",
  //   label: "POS",
  //   width: 150,
  // },
];

const PastOrdersTableColumns = [
  {
    dataKey: "number",
    label: "Order #",
    width: 70,
  },
  {
    dataKey: "status",
    label: "Status",
    width: 150,
  },
  {
    dataKey: "products",
    label: "Products",
    width: 280,
  },
  {
    dataKey: "customer",
    label: "Customer",
    width: 200,
  },
  {
    dataKey: "address",
    label: "Address",
    width: 300,
  },
  {
    dataKey: "receivedAt",
    label: "Received",
    width: 180,
  },
  {
    dataKey: "isDispatched",
    label: "Dispatch",
    width: 220,
  },
  {
    dataKey: "actions",
    label: "Actions",
    width: 160,
  },
  // {
  //   dataKey: "recalculate",
  //   label: "",
  //   width: 130,
  // },
  // {
  //   dataKey: "resendEmails",
  //   label: "",
  //   width: 130,
  // },
  // {
  //   dataKey: "pos",
  //   label: "POS",
  //   width: 150,
  // },
];

export { RecentOrdersTableColumns, PastOrdersTableColumns };
