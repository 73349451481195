import { endOfDay, format, isToday, set } from "date-fns";

import { apiInstance } from "../../config";
import { REPORT_TIME } from "../../common/constants";

const CREATE_REPORT_START_TYPE = "CREATE_REPORT_START";
const CREATE_REPORT_SUCCESSFUL_TYPE = "CREATE_REPORT_SUCCESSFUL";
const CREATE_REPORT_FAILED_TYPE = "CREATE_REPORT_FAILED";
const DELETE_REPORT_START_TYPE = "DELETE_REPORT_START";
const DELETE_REPORT_SUCCESSFUL_TYPE = "DELETE_REPORT_SUCCESSFUL";
const DELETE_REPORT_FAILED_TYPE = "DELETE_REPORT_FAILED";
const GENERATE_EXTERNAL_ORDER_REPORT_START_TYPE =
  "GENERATE_EXTERNAL_ORDER_REPORT_START";
const GENERATE_EXTERNAL_ORDER_REPORT_SUCCESSFUL_TYPE =
  "GENERATE_EXTERNAL_ORDER_REPORT_SUCCESSFUL";
const GENERATE_EXTERNAL_ORDER_REPORT_FAILED_TYPE =
  "GENERATE_EXTERNAL_ORDER_REPORT_FAILED";

export enum ReportType {
  Sales = "sales",
  Order_Placed = "order_placed",
}

/**
 *
 */
function actionCreateReport(reportType: ReportType, parameters: object) {
  return {
    types: [
      CREATE_REPORT_START_TYPE,
      CREATE_REPORT_SUCCESSFUL_TYPE,
      CREATE_REPORT_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/admin/report/${reportType}`, {
      ...parameters,
    }),
    payload: {
      type: reportType,
    },
  };
}

function actionDeleteReport(reportType: ReportType, reportId: object) {
  return {
    types: [
      DELETE_REPORT_START_TYPE,
      DELETE_REPORT_SUCCESSFUL_TYPE,
      DELETE_REPORT_FAILED_TYPE,
    ],
    promise: apiInstance.delete(`/admin/report/${reportType}/${reportId}`),
    payload: {
      reportId: reportId,
    },
  };
}
// @ts-ignore
function actionGenerateExternalOrderReport(parameters) {
  return {
    types: [
      GENERATE_EXTERNAL_ORDER_REPORT_START_TYPE,
      GENERATE_EXTERNAL_ORDER_REPORT_SUCCESSFUL_TYPE,
      GENERATE_EXTERNAL_ORDER_REPORT_FAILED_TYPE,
    ],
    promise: apiInstance.get("/admin/report/daily_external_orders", {
      params: {
        customDate: parameters.customDate,
      },
    }),
  };
}

const FETCH_REPORTS_START_TYPE = "FETCH_REPORTS_START";
const FETCH_REPORTS_SUCCESSFUL_TYPE = "FETCH_REPORTS_SUCCESSFUL";
const FETCH_REPORTS_FAILED_TYPE = "FETCH_REPORTS_FAILED";

/**
 *
 */
function actionFetchReports(reportType: ReportType) {
  return {
    types: [
      FETCH_REPORTS_START_TYPE,
      FETCH_REPORTS_SUCCESSFUL_TYPE,
      FETCH_REPORTS_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/admin/report/${reportType}`),
    payload: {
      type: reportType,
    },
  };
}

const DOWNLOAD_REPORTS_START_TYPE = "DOWNLOAD_REPORTS_START";
const DOWNLOAD_REPORTS_SUCCESSFUL_TYPE = "DOWNLOAD_REPORTS_SUCCESSFUL";
const DOWNLOAD_REPORTS_FAILED_TYPE = "DOWNLOAD_REPORTS_FAILED";

/**
 *
 */
function actionDownloadReport(
  reportType: ReportType,
  reportId: string,
  driver?: string,
  total?: boolean
) {
  let downloadURL = `/admin/report/${reportType}/${reportId}`;

  if (total) {
    downloadURL = `${downloadURL}/total`;
  }

  return {
    types: [
      DOWNLOAD_REPORTS_START_TYPE,
      DOWNLOAD_REPORTS_SUCCESSFUL_TYPE,
      DOWNLOAD_REPORTS_FAILED_TYPE,
    ],
    promise: apiInstance.get(downloadURL, {
      params: {
        driver,
      },
    }),
  };
}

function actionSetDate(date: Date, time: REPORT_TIME | null) {
  let range = date;
  if (time === REPORT_TIME.DayEnd || (!time && !isToday(new Date(date)))) {
    range = endOfDay(new Date(date));
  } else if (time === REPORT_TIME.EightPM) {
    range = set(new Date(date), { hours: 20, minutes: 0, seconds: 0 });
  } else if (time === REPORT_TIME.SixAM) {
    range = set(new Date(date), { hours: 6, minutes: 0, seconds: 0 });
  }

  return range;
}

function actionGenerateSalesForCourierReport(parameters: {
  startRange: Date;
  endRange: Date;
  orderCategory: String;
}) {
  return {
    types: [
      GENERATE_EXTERNAL_ORDER_REPORT_START_TYPE,
      GENERATE_EXTERNAL_ORDER_REPORT_SUCCESSFUL_TYPE,
      GENERATE_EXTERNAL_ORDER_REPORT_FAILED_TYPE,
    ],
    promise: apiInstance.get("/admin/report/sales_by_order_category", {
      params: {
        start_range: format(parameters.startRange, "yyyy-MM-dd HH:mm:ss.SSS"),
        end_range: format(parameters.endRange, "yyyy-MM-dd HH:mm:ss.SSS"),
        order_category: parameters.orderCategory,
      },
    }),
  };
}

export {
  actionCreateReport,
  actionDownloadReport,
  actionFetchReports,
  actionSetDate,
  actionDeleteReport,
  actionGenerateExternalOrderReport,
  actionGenerateSalesForCourierReport,
  CREATE_REPORT_START_TYPE,
  CREATE_REPORT_SUCCESSFUL_TYPE,
  CREATE_REPORT_FAILED_TYPE,
  DOWNLOAD_REPORTS_START_TYPE,
  DOWNLOAD_REPORTS_SUCCESSFUL_TYPE,
  DOWNLOAD_REPORTS_FAILED_TYPE,
  FETCH_REPORTS_START_TYPE,
  FETCH_REPORTS_SUCCESSFUL_TYPE,
  FETCH_REPORTS_FAILED_TYPE,
  DELETE_REPORT_START_TYPE,
  DELETE_REPORT_SUCCESSFUL_TYPE,
  DELETE_REPORT_FAILED_TYPE,
  GENERATE_EXTERNAL_ORDER_REPORT_START_TYPE,
  GENERATE_EXTERNAL_ORDER_REPORT_SUCCESSFUL_TYPE,
  GENERATE_EXTERNAL_ORDER_REPORT_FAILED_TYPE,
};
