import { fromJS, List } from "immutable";
import isEmpty from "lodash.isempty";

import {
  FETCH_DISPATCH_DRIVERS_FAILED_TYPE,
  FETCH_DISPATCH_DRIVERS_START_TYPE,
  FETCH_DISPATCH_DRIVERS_SUCCESSFUL_TYPE,
  FETCH_ORDER_MESSAGES_FAILED_TYPE,
  FETCH_ORDER_MESSAGES_START_TYPE,
  FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE,
  REASSIGN_ORDER_TO_DRIVER_FAILED_TYPE,
  REASSIGN_ORDER_TO_DRIVER_START_TYPE,
  SELECT_DRIVER_TYPE,
  UNSELECT_ALL_DRIVERS_TYPE,
  UPDATE_DRIVER_AVAILABILITY_FAILED_TYPE,
  UPDATE_DRIVER_AVAILABILITY_START_TYPE,
  UPDATE_DRIVER_AVAILABILITY_SUCCESSFUL_TYPE,
} from "./actions";

interface IDriversState {
  driversData: List<any>;
  orderMessages: List<any>;

  isFetchingOrderMessages: boolean;
  isFetchingDrivers: boolean;
  isReassigningOrder: boolean;
  isUpdatingDriver: boolean;
}

const initialDriversState: IDriversState = {
  driversData: List(),
  orderMessages: List(),

  isFetchingOrderMessages: false,
  isFetchingDrivers: false,
  isReassigningOrder: false,
  isUpdatingDriver: false,
};

function reducer(state = initialDriversState, { type, payload }: any) {
  switch (type) {
    case FETCH_DISPATCH_DRIVERS_START_TYPE: {
      return {
        ...state,
        isFetchingDrivers: true,
      };
    }

    case FETCH_DISPATCH_DRIVERS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        let driversList = state.driversData;

        responseData.forEach((responseDriver: any) => {
          const responseDriverMap = fromJS(responseDriver);

          const existingDriverIndex = driversList.findIndex(
            (existingDriver: any) =>
              existingDriver.get("id") === responseDriverMap.get("id")
          );

          if (existingDriverIndex !== -1) {
            driversList = driversList.update(
              existingDriverIndex,
              (existingDriver: any) => existingDriver.merge(responseDriverMap)
            );
          } else {
            driversList = driversList.push(responseDriverMap);
          }
        });

        state.driversData = driversList;
      }

      return {
        ...state,
        isFetchingDrivers: false,
      };
    }

    case FETCH_DISPATCH_DRIVERS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingDrivers: false,
      };
    }

    case FETCH_ORDER_MESSAGES_START_TYPE: {
      return {
        ...state,
        isFetchingOrderMessages: true,
      };
    }

    case FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.orderMessages = fromJS(responseData);
      }

      return {
        ...state,
        isFetchingOrderMessages: false,
      };
    }

    case FETCH_ORDER_MESSAGES_FAILED_TYPE: {
      return {
        ...state,
        isFetchingOrderMessages: false,
      };
    }

    case REASSIGN_ORDER_TO_DRIVER_START_TYPE: {
      return {
        ...state,
        isReassigningOrder: true,
      };
    }

    case REASSIGN_ORDER_TO_DRIVER_FAILED_TYPE: {
      return {
        ...state,
        isReassigningOrder: false,
      };
    }

    case UPDATE_DRIVER_AVAILABILITY_START_TYPE: {
      return {
        ...state,
        isUpdatingDriver: false,
      };
    }

    case UPDATE_DRIVER_AVAILABILITY_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const responseAccountMap = fromJS(responseData);
        let driversList, driverIndex;

        driversList = state.driversData;

        driverIndex = driversList.findIndex(
          (driver: any) => driver.get("id") === responseAccountMap.get("id")
        );

        if (driverIndex !== -1) {
          driversList = driversList.update(driverIndex, (driver: any) =>
            driver.merge(responseAccountMap)
          );
        }

        state.driversData = driversList;
      }

      return {
        ...state,
        isUpdatingDriver: false,
      };
    }

    case UPDATE_DRIVER_AVAILABILITY_FAILED_TYPE: {
      return {
        ...state,
        isUpdatingDriver: false,
      };
    }

    case SELECT_DRIVER_TYPE: {
      const { driverId } = payload;

      const driverIndex = state.driversData.findIndex(
        (driver: any) => driver.get("id") === driverId
      );

      state.driversData = state.driversData.update(
        driverIndex,
        (driver: any) => {
          const isRowSelected = Boolean(driver.get("selected"));

          return driver.set("selected", !isRowSelected);
        }
      );

      return {
        ...state,
      };
    }

    case UNSELECT_ALL_DRIVERS_TYPE: {
      state.driversData = state.driversData.map((driver: any) =>
        driver.set("selected", false)
      );

      return {
        ...state,
      };
    }

    default:
      return state;
  }
}

export default reducer;
