import {
  Button,
  Card,
  CardHeader,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { CloudDownload as CloudDownloadIcon } from "@material-ui/icons";
import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TableCellProps } from "react-virtualized";

import { Table } from "../../common";
import { actionDownloadReport, ReportType } from "./actions";
import { DriversReportsTableColumns } from "./helpers";
import styles from "./styles";

interface SalesDriversReportDashboardProps extends WithStyles<typeof styles> {}

function SalesDriversReportDashboard({
  classes,
}: SalesDriversReportDashboardProps) {
  const reportsTableColumns = useRef(DriversReportsTableColumns);

  // @ts-ignore
  const { reportId } = useParams();

  const dispatch = useDispatch();

  const driverAccounts = useSelector(
    (state: any) => state.dispatch.driversData
  );

  /**
   *
   */
  const _memoizedRowGetter = useCallback(
    ({ index }) => driverAccounts.get(index),
    [driverAccounts]
  );

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");

    if (dataKey === "download") {
      cellContent = (
        <Button
          size="small"
          disableElevation
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          onClick={() =>
            dispatch(
              actionDownloadReport(ReportType.Sales, reportId, undefined, rowId)
            )
          }
        >
          Download
        </Button>
      );

      cellClass = classes.blockTableCell;
    }

    return {
      cellContent,
      cellClass,
    };
  };

  return (
    <>
      <Card square className={classes.driverSalesReportCard}>
        <CardHeader
          subheader="Sales reports for Drivers"
          className={classes.driverSalesReportCardHeader}
        />

        <Table
          renderEmptyHeader
          tableRowHeight={65}
          rowCount={driverAccounts.size}
          tableHeaderRowHeight={50}
          rowGetter={_memoizedRowGetter}
          columns={reportsTableColumns.current}
          determineCellContent={determineCellContent}
        />
      </Card>
    </>
  );
}

export default withStyles(styles)(SalesDriversReportDashboard);
