import {
  Collapse,
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Commute as CommuteIcon,
  Description as ReportIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Home as HomeIcon,
  PeopleAlt as UsersIcon,
  ShoppingBasket as ShoppingBasketIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@material-ui/icons";
import { Omit } from "@material-ui/types";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

// Components
import ListItemLink from "./ListItemLink";

// Utilities
import config, { apiInstance } from "../../config";

import { useStyles } from "./styles";

export interface NavigatorProps extends Omit<DrawerProps, "classes"> {}

interface NavigationState {
  isOrdersOpen: boolean;
  isCartOpen: boolean;
  isReportsOpen: boolean;
  isDispatchOpen: boolean;
}

function Navigator(props: NavigatorProps) {
  const classes = useStyles();

  const [apiVersion, setApiVersion] = useState<string | null>(null);
  const [navigationState, setNavigationState] = useState<NavigationState>({
    isOrdersOpen: false,
    isCartOpen: false,
    isReportsOpen: false,
    isDispatchOpen: false,
  });

  // Get API version
  useEffect(() => {
    apiInstance.get(`/version`).then(({ data = {} }) => {
      const { version } = data;
      if (!version) return;

      setApiVersion(version);
    });
  }, []);

  const { version } = config;

  return (
    <Drawer variant="permanent" open {...props}>
      <List disablePadding>
        <ListItemLink icon={<HomeIcon />} text="Home" to="/" />

        <ListItem
          button
          onClick={() =>
            setNavigationState((state) => ({
              ...state,
              isOrdersOpen: !navigationState.isOrdersOpen,
            }))
          }
          className={clsx(classes.item, classes.itemCategory)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ShoppingCartIcon />
          </ListItemIcon>

          <ListItemText>Orders</ListItemText>

          {navigationState.isOrdersOpen ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>

        <Collapse in={navigationState.isOrdersOpen}>
          <Divider />

          <List component="div" disablePadding>
            <ListItemLink text="Recent" to="/" />
            <ListItemLink text="Past" to="/orders/past" />
          </List>
        </Collapse>

        <ListItem
          button
          onClick={() =>
            setNavigationState((state) => ({
              ...state,
              isDispatchOpen: !navigationState.isDispatchOpen,
            }))
          }
          className={clsx(classes.item, classes.itemCategory)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <CommuteIcon />
          </ListItemIcon>

          <ListItemText>Dispatch</ListItemText>

          {navigationState.isDispatchOpen ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>

        <Collapse in={navigationState.isDispatchOpen}>
          <Divider />

          <List component="div" disablePadding>
            <ListItemLink text="Drivers Availability" to="/dispatch/drivers" />
            <ListItemLink text="Active and Delivered Today" to="/dispatch" />
            <ListItemLink text="Past Delivered" to="/dispatch/delivered" />
          </List>
        </Collapse>

        <ListItem
          button
          onClick={() =>
            setNavigationState((state) => ({
              ...state,
              isReportsOpen: !navigationState.isReportsOpen,
            }))
          }
          className={clsx(classes.item, classes.itemCategory)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ReportIcon />
          </ListItemIcon>

          <ListItemText>Reports</ListItemText>

          {navigationState.isReportsOpen ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </ListItem>

        <Collapse in={navigationState.isReportsOpen}>
          <Divider />

          <List component="div" disablePadding>
            <ListItemLink text="Sales" to="/reports/sales" />
            <ListItemLink text="Order placed" to="/reports/placed" />
          </List>
        </Collapse>

        <ListItemLink icon={<UsersIcon />} text="Accounts" to="/accounts" />
        <ListItemLink
          icon={<ShoppingBasketIcon />}
          text="Products"
          to="/products"
        />
      </List>
      {Boolean(version) && (
        <div className={classes.version}>
          <span>v{version}</span>
          {Boolean(apiVersion) && <span>&ensp;(API v{apiVersion})</span>}
        </div>
      )}
    </Drawer>
  );
}

export { Navigator };
