import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { List as ImmutableList } from "immutable";
import debounce from "lodash.debounce";
import isEmpty from "lodash.isempty";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionSearchProducts } from "../../store/Products/actions";
import styles from "./styles";
import { actionClearSearchProducts } from "../Products/actions";
import { COMPANY } from "../../common/constants";

interface OrderItemProps extends WithStyles<typeof styles> {
  productErrors: any;
  products: ImmutableList<any>;
  handleChange: (arg0: any) => any;
  setFieldValue: (arg0: any, arg1?: any) => any;
  values: any;
}

function OrderItems({
  classes,
  products,
  handleChange,
  setFieldValue,
  productErrors,
  values,
}: OrderItemProps) {
  const dispatch = useDispatch();

  // @ts-ignore
  const searchedProducts = useSelector((state) => state.products.searchedData);

  const onSearchProduct = debounce((searchStr: string) => {
    dispatch(actionSearchProducts(searchStr, values.company));
  }, 300);

  useEffect(
    () => () => {
      dispatch(actionClearSearchProducts());
    },
    [dispatch]
  );

  return (
    <List>
      {products.map((product: any, productIndex: number) => {
        if (!product) {
          return null;
        }

        const {
          id: productId,
          quantity: productQuantity,
          notes: productNotes,
          company: productCompany,
        } = product;

        return (
          <ListItem
            key={`product-item-${productId}-${productIndex}`}
            className={classes.orderProductItem}
          >
            <Grid container spacing={2}>
              <Grid item lg={2}>
                {!productCompany && (
                  <Autocomplete
                    options={[COMPANY.Lcbo, COMPANY.Tbs]}
                    getOptionSelected={(option: any, value: any) => {
                      if (!isEmpty(option) && !isEmpty(value)) {
                        return option.id === value.id;
                      }
                      return false;
                    }}
                    onChange={(_, company: any) => {
                      setFieldValue("company", company);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        disable
                        fullWidth
                        size="small"
                        label="Company"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                {!!productCompany && (
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    label="Company"
                    variant="outlined"
                    value={productCompany}
                  />
                )}
              </Grid>
              <Grid item lg={3}>
                <Autocomplete
                  value={product}
                  options={searchedProducts.toJS()}
                  getOptionLabel={(product: any) =>
                    !isEmpty(product)
                      ? `${product.name} - ${product.size} - ${product.company}`.toUpperCase()
                      : ""
                  }
                  filterOptions={(x) => x}
                  getOptionSelected={(option: any, value: any) => {
                    if (!isEmpty(option) && !isEmpty(value)) {
                      return option.id === value.id;
                    }

                    return false;
                  }}
                  onChange={(_, searchedOption) =>
                    !isEmpty(searchedOption) &&
                    setFieldValue(`products[${productIndex}]`, {
                      ...product,
                      id: searchedOption.id,
                      name: searchedOption.name,
                      size: searchedOption.size,
                      quantity: 1,
                      price: searchedOption.price,
                      company: searchedOption.company,
                    })
                  }
                  noOptionsText="Search product"
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={
                        !isEmpty(productErrors) &&
                        !isEmpty(productErrors[productIndex]) &&
                        productErrors[productIndex].name
                      }
                      helperText={
                        !isEmpty(productErrors) &&
                        !isEmpty(productErrors[productIndex]) &&
                        productErrors[productIndex].name
                      }
                      disabled
                      size="small"
                      variant="outlined"
                      label="Product Name"
                      onChange={({ target }) => onSearchProduct(target.value)}
                    />
                  )}
                />
              </Grid>

              <Grid item lg={1}>
                <TextField
                  fullWidth
                  label="Quantity"
                  size="small"
                  defaultValue={1}
                  variant="outlined"
                  error={
                    !isEmpty(productErrors) &&
                    !isEmpty(productErrors[productIndex]) &&
                    productErrors[productIndex].quantity
                  }
                  helperText={
                    !isEmpty(productErrors) &&
                    !isEmpty(productErrors[productIndex]) &&
                    productErrors[productIndex].quantity
                  }
                  name={`products[${productIndex}].quantity`}
                  value={productQuantity}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item lg={2}>
                <TextField
                  fullWidth
                  label="Notes"
                  size={"small"}
                  placeholder="Notes"
                  variant="outlined"
                  name={`products[${productIndex}].notes`}
                  value={productNotes}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item lg={1} style={{ position: "relative" }}>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      setFieldValue(
                        `products`,
                        products.filter((_, index) => index !== productIndex)
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
}

export default withStyles(styles)(OrderItems);
