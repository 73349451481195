import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  withStyles,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { startOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";

import { actionSignOutAccount } from "../../Auth/actions";
import { ORDER_TYPE } from "../../common/constants";
import { actionSearchProducts } from "../../store/Products/actions";
import Accounts from "../Accounts";
import { actionFetchDispatchDrivers } from "../Dispatch/actions";
import PastOrders from "../Orders/PastOrders";
import RecentOrders from "../Orders/RecentOrders";
import { ProductsDashboard } from "../Products";
import { Navigator } from "../../common";
import { actionFetchPaymentMethods } from "../../store/PaymentMethods/actions";
import Dispatch from "../Dispatch";
import { CreateOrder, EditOrder, Reorder } from "../Orders";
import { actionFetchOrders } from "../Orders/actions";
import {
  OrderPlacedReportDashboard,
  SalesDriversReportDashboard,
  SalesReportDashboard,
} from "../Reports";
import HomeHeader from "./Header";
import { drawerWidth, styles } from "./styles";

function HomeComponent() {
  return (
    <>
      <HomeHeader />
      <RecentOrders />
    </>
  );
}

function Home({ classes }: any) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(actionFetchOrders(ORDER_TYPE.Recent, undefined, "*"));
    dispatch(
      actionFetchOrders(ORDER_TYPE.Completed, undefined, "*", {
        fromDate: startOfDay(new Date()).toISOString(),
        toDate: new Date().toISOString(),
      })
    );

    dispatch(actionFetchDispatchDrivers());
    dispatch(actionSearchProducts());
    dispatch(actionFetchPaymentMethods());
  }, [dispatch]);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(actionSignOutAccount());
    window.location.href = "/signin";
  };

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Navigator PaperProps={{ style: { width: drawerWidth } }} />
      </nav>

      <div className={classes.app}>
        <AppBar elevation={0} position="static" style={{ background: '#000000' }}>
          <Toolbar className={classes.toolBar}>
            <>
              <IconButton
                aria-label="Account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          </Toolbar>
        </AppBar>

        <main className={classes.main}>
          <Switch>
            <Route path="/accounts" component={Accounts} />
            <Route path="/dispatch" component={Dispatch} />
            <Route
              path="/reports/sales/drivers/:reportId"
              component={SalesDriversReportDashboard}
            />
            <Route path="/reports/sales" component={SalesReportDashboard} />
            <Route
              path="/reports/placed"
              component={OrderPlacedReportDashboard}
            />
            <Route path="/reorder/:orderId" component={Reorder} />
            <Route path="/orders/past" component={PastOrders} />
            <Route path="/order/:orderId" component={EditOrder} />
            <Route path="/order" component={CreateOrder} />
            <Route path="/products" component={ProductsDashboard} />
            <Route path="/" component={HomeComponent} />
          </Switch>
        </main>
      </div>
    </div>
  );
}

export default withStyles(styles)(Home);
