import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(2),
    },
    blockTableCell: {
      alignItems: "flex-start",
    },
    ml8: {
      marginLeft: 8,
    },
    salesReportCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    salesReportCardHeader: {
      backgroundColor: "white",
    },
    driverSalesReportCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    driverSalesReportCardHeader: {
      backgroundColor: "white",
    },
    orderPlacedReportCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    orderPlacedReportCardHeader: {
      backgroundColor: "white",
    },
  });

export default styles;
