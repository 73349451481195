import { ACCOUNTS_TYPE } from "../../common/constants";
import { apiInstance } from "../../config";

const FETCH_ACCOUNTS_START_TYPE = "FETCH_ACCOUNTS";
const FETCH_ACCOUNTS_SUCCESSFUL_TYPE = "FETCH_ACCOUNTS_SUCCESSFUL";
const FETCH_ACCOUNTS_FAILED_TYPE = "FETCH_ACCOUNTS_FAILED";

interface IActionFetchAccountsParams {
  accountsType?: ACCOUNTS_TYPE;
  cursor?: string;
  per_page?: number | string;
}

/**
 *
 */
function actionFetchAccounts(params?: IActionFetchAccountsParams) {
  return {
    types: [
      FETCH_ACCOUNTS_START_TYPE,
      FETCH_ACCOUNTS_SUCCESSFUL_TYPE,
      FETCH_ACCOUNTS_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/admin/account`, {
      params: {
        ...params,
      },
    }),
    payload: {
      accountsType: params?.accountsType,
    },
  };
}

const CREATE_ACCOUNT_START_TYPE = "CREATE_ACCOUNT_START";
const CREATE_ACCOUNT_SUCCESSFUL_TYPE = "CREATE_ACCOUNT_SUCCESSFUL";
const CREATE_ACCOUNT_FAILED_TYPE = "CREATE_ACCOUNT_FAILED";

/**
 *
 */
function actionCreateAccount(formFields: any) {
  return {
    types: [
      CREATE_ACCOUNT_START_TYPE,
      CREATE_ACCOUNT_SUCCESSFUL_TYPE,
      CREATE_ACCOUNT_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/admin/account`, {
      ...formFields,
    }),
  };
}

const EDIT_ACCOUNT_START_TYPE = "EDIT_ACCOUNT_START";
const EDIT_ACCOUNT_SUCCESSFUL_TYPE = "EDIT_ACCOUNT_SUCCESSFUL";
const EDIT_ACCOUNT_FAILED_TYPE = "EDIT_ACCOUNT_FAILED";

/**
 *
 */
function actionEditAccount(accountId: string, formFields: any) {
  return {
    types: [
      EDIT_ACCOUNT_START_TYPE,
      EDIT_ACCOUNT_SUCCESSFUL_TYPE,
      EDIT_ACCOUNT_FAILED_TYPE,
    ],
    promise: apiInstance.patch(`/account`, {
      accountId,
      ...formFields,
    }),
  };
}

const DEACTIVATE_ACCOUNT_START_TYPE = "DEACTIVATE_ACCOUNT_START_TYPE";
const DEACTIVATE_ACCOUNT_SUCCESSFUL_TYPE = "DEACTIVATE_ACCOUNT_SUCCESSFUL_TYPE";
const DEACTIVATE_ACCOUNT_FAILED_TYPE = "DEACTIVATE_ACCOUNT_FAILED_TYPE";

/**
 * Deactivate a user account
 */
function actionDeactivateAccount(accountId: string) {
  return {
    types: [
      DEACTIVATE_ACCOUNT_START_TYPE,
      DEACTIVATE_ACCOUNT_SUCCESSFUL_TYPE,
      DEACTIVATE_ACCOUNT_FAILED_TYPE,
    ],
    promise: apiInstance.delete(`/admin/account/${accountId}`),
  };
}

const SEARCH_ACCOUNTS_START_TYPE = "SEARCH_ACCOUNTS_START";
const SEARCH_ACCOUNTS_SUCCESSFUL_TYPE = "SEARCH_ACCOUNTS_SUCCESSFUL";
const SEARCH_ACCOUNTS_FAILED_TYPE = "SEARCH_ACCOUNTS_FAILED";

/**
 *
 */
function actionSearchAccounts(searchTerm: string, accountType?: ACCOUNTS_TYPE) {
  return {
    types: [
      SEARCH_ACCOUNTS_START_TYPE,
      SEARCH_ACCOUNTS_SUCCESSFUL_TYPE,
      SEARCH_ACCOUNTS_FAILED_TYPE,
    ],
    promise: apiInstance.get("/search/accounts", {
      params: {
        searchTerm,
        accountType,
      },
    }),
  };
}

const SET_ACCOUNT_NOTES = "SET_ACCOUNT_NOTES";

/**
 * Set account notes (are changed by themselves in create/edit order)
 * 
 * @param accountId - Account ID
 * @param notes     - Customer notes
 */
function actionSetAccountNotes(accountId: string, notes: string) {
  return {
    type: SET_ACCOUNT_NOTES,
    payload: {
      accountId,
      notes,
    }
  }
}

const CLEAR_SEARCHED_ACCOUNTS_TYPE = "CLEAR_SEARCHED_ACCOUNTS";

/**
 *
 */
function actionClearSearchedAccounts() {
  return {
    type: CLEAR_SEARCHED_ACCOUNTS_TYPE,
  };
}

export {
  actionCreateAccount,
  actionDeactivateAccount,
  actionFetchAccounts,
  actionSearchAccounts,
  actionEditAccount,
  actionClearSearchedAccounts,
  actionSetAccountNotes,
  CREATE_ACCOUNT_START_TYPE,
  CREATE_ACCOUNT_SUCCESSFUL_TYPE,
  CREATE_ACCOUNT_FAILED_TYPE,
  DEACTIVATE_ACCOUNT_START_TYPE,
  DEACTIVATE_ACCOUNT_SUCCESSFUL_TYPE,
  DEACTIVATE_ACCOUNT_FAILED_TYPE,
  EDIT_ACCOUNT_START_TYPE,
  EDIT_ACCOUNT_SUCCESSFUL_TYPE,
  EDIT_ACCOUNT_FAILED_TYPE,
  FETCH_ACCOUNTS_START_TYPE,
  FETCH_ACCOUNTS_SUCCESSFUL_TYPE,
  FETCH_ACCOUNTS_FAILED_TYPE,
  CLEAR_SEARCHED_ACCOUNTS_TYPE,
  SEARCH_ACCOUNTS_START_TYPE,
  SEARCH_ACCOUNTS_SUCCESSFUL_TYPE,
  SEARCH_ACCOUNTS_FAILED_TYPE,
  SET_ACCOUNT_NOTES,
};
