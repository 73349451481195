import { createMuiTheme, fade, lighten } from "@material-ui/core";

let theme = createMuiTheme({
  palette: {
    background: {
      default: "#eaeff1",
    },
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiCardHeader: {
      // root: {
      //   padding: 0,
      // },
      action: {
        marginTop: 0,
        marginRight: 0,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
        borderBottom: `1px solid ${lighten(
          fade(theme.palette.divider, 1),
          0.88
        )}`,
      },
    },
    MuiSelect: {
      outlined: {
        padding: "10px 14px",
        fontSize: "12px",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: "0.7rem",
        lineHeight: "1rem",
        padding: "16px 0px 16px 10px",
      },
      head: {
        lineHeight: "1rem",
      },
    },
    MuiTabs: {
      root: {
        // marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        // backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        // [theme.breakpoints.up("md")]: {
        //   padding: 0,
        //   minWidth: 0,
        // },
      },
    },
    MuiIconButton: {
      root: {
        // padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: `${theme.spacing(2)}px !important`,
        paddingRight: `${theme.spacing(2)}px !important`,
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItemText: {
      primary: {
        // fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

export { theme };
