import {
  Button,
  Card,
  CardHeader,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { AddAddressDialog } from "../Address/Dialogs";
import { AddAccount } from "../Accounts/Dialogs";
import { styles } from "./styles";

interface HomeHeaderProps extends WithStyles<typeof styles> {}

function HomeHeader({ classes }: HomeHeaderProps) {
  const history = useHistory();

  const [showAddAccountModal, setAddAccountModal] = useState(false);
  const [showAddAddressModal, setAddAddressModal] = useState(false);

  return (
    <Card square elevation={0} className={classes.mainCard}>
      <CardHeader
        action={
          <>
            <Button
              color="primary"
              variant="contained"
              className={classes.headerButton}
              onClick={() => setAddAccountModal(true)}
            >
              Add a user account
            </Button>

            <Button
              color="primary"
              variant="contained"
              className={classes.headerButton}
              onClick={() => setAddAddressModal(true)}
            >
              Add an address
            </Button>

            <Button
              color="primary"
              variant="contained"
              className={classes.headerButton}
              onClick={() => history.push("/order")}
            >
              Create an order
            </Button>

            <AddAddressDialog
              show={showAddAddressModal}
              onClose={() => setAddAddressModal(false)}
            />

            <AddAccount
              show={showAddAccountModal}
              onlyCustomer={false}
              onClose={() => setAddAccountModal(false)}
            />
          </>
        }
      />
    </Card>
  );
}

export default withStyles(styles)(HomeHeader);
