import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";

interface TableHeadProps {
  tableHeader?: JSX.Element;
}

const TableHead = React.memo((props: TableHeadProps) => {
  const { tableHeader } = props;

  return (
    <AppBar color="default" position="static" elevation={0}>
      <Toolbar>{tableHeader}</Toolbar>
    </AppBar>
  );
});

export default TableHead;
