import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ErrorSnackbarContext } from "../../../App";
import { actionDeactivateAccount } from "../../../store/Accounts/actions";
import styles from "../styles";
import { IDeactivateAccount } from "../types";

interface IConfirmDeactivationProps {
  account: IDeactivateAccount | null;
  show: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => styles(theme));

function DeactivateAccount({
  account,
  show,
  onClose,
}: IConfirmDeactivationProps) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const isDeactivating = useSelector(({ accounts }: any) => accounts.isDeactivatingAccount);

  if (!account) return null;

  const deactivateAccount = async (
    notify: (message: string, error?: boolean) => void
  ) => {
    try {
      await dispatch(actionDeactivateAccount(account.id));

      notify("Successfully deactivated user account", false);
      onClose();
    } catch (e) {
      notify("Error occurred while deactivating user account", true);
    }
  };

  return (
    <Dialog open={show} onClose={onClose}>
      <ErrorSnackbarContext.Consumer>
        {({ toggleAlerts }) => (
          <>
            <DialogTitle>Deactivate Account</DialogTitle>

            <Divider />

            <DialogContent>
              Are you sure you want to deactivate this account?
              <div className={classes.deactivateAccountName}>
                {account.name || "N/A"}
              </div>
              <div className={classes.deactivateAccountEmail}>
                {account.email}
              </div>
            </DialogContent>

            <Divider />

            <DialogActions>
              <Button
                color="secondary"
                disabled={isDeactivating}
                variant="contained"
                onClick={() => onClose()}
              >
                Cancel
              </Button>

              <Button
                color="primary"
                disabled={isDeactivating}
                variant="contained"
                onClick={() => deactivateAccount(toggleAlerts)}
              >
                Confirm
              </Button>
            </DialogActions>
          </>
        )}
      </ErrorSnackbarContext.Consumer>
    </Dialog>
  );
}

export default DeactivateAccount;
