import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    blockTableCell: {
      alignItems: "flex-start",
    },
    productsCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    productsCardHeader: {
      backgroundColor: "white",
    },
    companyCell: {
      textTransform: "capitalize",
    },
  });

export default styles;
