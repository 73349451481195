import { fromJS, List } from "immutable";
import isEmpty from "lodash.isempty";

import {
  MARK_ORDER_COMPLETE_SUCCESSFUL_TYPE,
  REASSIGN_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
} from "../Dispatch/actions";
import {
  CANCEL_ORDER_SUCCESSFUL_TYPE,
  CLEAR_SEARCH_ORDER_TYPE,
  COMPLETE_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
  CREATE_ORDER_FAILED_TYPE,
  CREATE_ORDER_START_TYPE,
  CREATE_ORDER_SUCCESSFUL_TYPE,
  DELETE_ORDER_ITEM_FAILED_TYPE,
  DELETE_ORDER_ITEM_START_TYPE,
  DELETE_ORDER_ITEM_SUCCESSFUL_TYPE,
  DISPATCH_ORDER_TO_DRIVER_FAILED_TYPE,
  DISPATCH_ORDER_TO_DRIVER_START_TYPE,
  DISPATCH_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
  FETCH_ORDERS_FAILED_TYPE,
  FETCH_ORDERS_START_TYPE,
  FETCH_ORDERS_SUCCESSFUL_TYPE,
  FETCH_ORDER_MESSAGES_FAILED_TYPE,
  FETCH_ORDER_MESSAGES_START_TYPE,
  FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE,
  POST_ORDER_MESSAGE_FAILED_TYPE,
  POST_ORDER_MESSAGE_START_TYPE,
  POST_ORDER_MESSAGE_SUCCESSFUL_TYPE,
  RE_ORDER_FAILED_TYPE,
  RE_ORDER_START_TYPE,
  RE_ORDER_SUCCESSFUL_TYPE,
  SEARCH_ORDERS_FAILED_TYPE,
  SEARCH_ORDERS_START_TYPE,
  SEARCH_ORDERS_SUCCESSFUL_TYPE,
  SELECT_ORDER_ROW_TYPE,
  UNSELECT_ALL_ORDERS_ROW_TYPE,
  UPDATE_ORDER_FAILED_TYPE,
  UPDATE_ORDER_START_TYPE,
  UPDATE_ORDER_SUCCESSFUL_TYPE,
} from "./actions";
import type { OrderRow } from "./types";
import { createOrderFromAPIResponse } from "./utils";

interface IOrdersState {
  data: List<OrderRow>;
  activeOrdersDataCursor: string | null;
  inActiveOrdersDataCursor: string | null;
  completedOrdersDataCursor: string | null;

  activeOrdersDataRemaining: number | null;
  inActiveOrdersDataRemaining: number | null;
  completedOrdersDataRemaining: number | null;

  searchedData: List<OrderRow>;
  inActiveOrdersSearchDataCursor: string | null;
  inActiveOrdersSearchDataRemaining: number | null;

  completedOrdersSearchDataCursor: string | null;
  completedOrdersSearchDataRemaining: number | null;

  isCreatingOrder: boolean;
  isReordering: boolean;
  isUpdatingOrder: boolean;
  isDispatchingOrder: boolean;
  isDeletingOrder: boolean;
  isFetchingOrders: boolean;
  isFetchingOrderMessages: boolean;
  isPostingOrderMessage: boolean;
  isSearchingOrders: boolean;
}

const initialOrdersState: IOrdersState = {
  data: List<OrderRow>(),
  activeOrdersDataCursor: null,
  inActiveOrdersDataCursor: null,
  completedOrdersDataCursor: null,

  activeOrdersDataRemaining: 0,
  inActiveOrdersDataRemaining: 0,
  completedOrdersDataRemaining: 0,

  searchedData: List<OrderRow>(),
  inActiveOrdersSearchDataCursor: null,
  inActiveOrdersSearchDataRemaining: 0,

  completedOrdersSearchDataCursor: null,
  completedOrdersSearchDataRemaining: 0,

  isCreatingOrder: false,
  isReordering: false,
  isUpdatingOrder: false,
  isDispatchingOrder: false,
  isDeletingOrder: false,
  isFetchingOrders: false,
  isFetchingOrderMessages: false,
  isSearchingOrders: false,
  isPostingOrderMessage: false,
};

// @ts-ignore
function reducer(state = initialOrdersState, { type, payload }) {
  switch (type) {
    case CREATE_ORDER_START_TYPE: {
      return {
        ...state,
        isCreatingOrder: true,
      };
    }

    case CREATE_ORDER_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const orderMap = createOrderFromAPIResponse(responseData);
        state.data = state.data.push(orderMap);
      }

      return {
        ...state,
        isCreatingOrder: false,
      };
    }

    case CREATE_ORDER_FAILED_TYPE: {
      return {
        ...state,
        isCreatingOrder: false,
      };
    }

    case RE_ORDER_START_TYPE: {
      return {
        ...state,
        isReordering: true,
      };
    }

    case RE_ORDER_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const orderMap = createOrderFromAPIResponse(responseData);
        state.data = state.data.push(orderMap);
      }

      return {
        ...state,
        isReordering: false,
      };
    }

    case RE_ORDER_FAILED_TYPE: {
      return {
        ...state,
        isReordering: false,
      };
    }

    case FETCH_ORDERS_START_TYPE: {
      return {
        ...state,
        isFetchingOrders: true,
      };
    }

    case FETCH_ORDERS_SUCCESSFUL_TYPE: {
      const { data: responseData, orders_type } = payload;
      const { orders: responseOrders, cursor, remaining } = responseData;

      if (!isEmpty(responseOrders)) {
        let ordersList = state.data;

        responseOrders.forEach((responseOrder: any) => {
          const responseOrderMap = createOrderFromAPIResponse(responseOrder);

          const existingOrderIndex = ordersList.findIndex(
            (existingOrder) =>
              // @ts-ignore
              existingOrder.get("id") === responseOrderMap.get("id")
          );

          if (existingOrderIndex !== -1) {
            ordersList = ordersList.update(
              existingOrderIndex,
              (existingOrder) =>
                // @ts-ignore
                existingOrder.mergeWith((oldVal, newVal, key) => {
                  if (key === "selected") {
                    return oldVal;
                  }

                  return newVal;
                }, responseOrderMap)
            );
          } else {
            ordersList = ordersList.push(responseOrderMap);
          }
        });

        state.data = ordersList;
      }

      if (orders_type === "recent") {
        return {
          ...state,
          activeOrdersDataCursor: cursor,
          activeOrdersDataRemaining: remaining,
          isFetchingOrders: false,
        };
      } else if (orders_type === "completed") {
        return {
          ...state,
          completedOrdersDataCursor: cursor,
          completedOrdersDataRemaining: remaining,
          isFetchingOrders: false,
        };
      } else {
        return {
          ...state,
          inActiveOrdersDataCursor: cursor,
          inActiveOrdersDataRemaining: remaining,
          isFetchingOrders: false,
        };
      }
    }

    case FETCH_ORDERS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingOrders: false,
      };
    }

    case SEARCH_ORDERS_START_TYPE: {
      return {
        ...state,
        isSearchingOrders: true,
      };
    }

    case SEARCH_ORDERS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;
      const { orders: responseOrders, cursor, remaining } = responseData;

      if (!isEmpty(responseOrders)) {
        let ordersList = List();

        responseOrders.forEach((responseOrder: any) => {
          const responseOrderMap = createOrderFromAPIResponse(responseOrder);
          ordersList = ordersList.push(responseOrderMap);
        });

        state.searchedData = ordersList;
      }

      return {
        ...state,
        inActiveOrdersSearchDataCursor: cursor,
        inActiveOrdersSearchDataRemaining: remaining,
        isSearchingOrders: false,
      };
    }

    case SEARCH_ORDERS_FAILED_TYPE: {
      return {
        ...state,
        isSearchingOrders: false,
      };
    }

    case FETCH_ORDER_MESSAGES_START_TYPE: {
      return {
        ...state,
        isFetchingOrderMessages: true,
      };
    }

    case FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE: {
      const { data: responseData, orderId } = payload;

      if (!isEmpty(responseData)) {
        let ordersList = state.data;

        const existingOrderIndex = ordersList.findIndex(
          (existingOrder) =>
            // @ts-ignore
            existingOrder.get("id") === orderId
        );

        if (existingOrderIndex !== -1) {
          ordersList = ordersList.update(
            existingOrderIndex,
            (existingOrder) => {
              // @ts-ignore
              return existingOrder.set("messages", fromJS(responseData));
            }
          );
        }

        state.data = ordersList;
      }

      return {
        ...state,
        isFetchingOrderMessages: false,
      };
    }

    case FETCH_ORDER_MESSAGES_FAILED_TYPE: {
      return {
        ...state,
        isFetchingOrderMessages: false,
      };
    }

    case POST_ORDER_MESSAGE_START_TYPE: {
      return {
        ...state,
        isPostingOrderMessage: true,
      };
    }

    case POST_ORDER_MESSAGE_SUCCESSFUL_TYPE: {
      const { data: responseData, orderId } = payload;

      if (!isEmpty(responseData)) {
        let ordersList = state.data;

        const existingOrderIndex = ordersList.findIndex(
          (existingOrder) =>
            // @ts-ignore
            existingOrder.get("id") === orderId
        );

        if (existingOrderIndex !== -1) {
          ordersList = ordersList.update(
            existingOrderIndex,
            (existingOrder) => {
              // @ts-ignore
              return existingOrder.set("messages", fromJS(responseData));
            }
          );
        }

        state.data = ordersList;
      }

      return {
        ...state,
        isPostingOrderMessage: false,
      };
    }

    case POST_ORDER_MESSAGE_FAILED_TYPE: {
      return {
        ...state,
        isPostingOrderMessage: false,
      };
    }

    case CLEAR_SEARCH_ORDER_TYPE: {
      state.searchedData = List();

      return {
        ...state,
      };
    }

    case SELECT_ORDER_ROW_TYPE: {
      const { orderID } = payload;

      state.data = state.data.update(
        state.data.findIndex((order: any) => order.get("id") === orderID),
        (order: any) => {
          const isRowSelected = Boolean(order.get("selected"));

          return order.set("selected", !isRowSelected);
        }
      );

      return {
        ...state,
      };
    }

    case UNSELECT_ALL_ORDERS_ROW_TYPE: {
      state.data = state.data.map((order: any) => order.set("selected", false));

      return {
        ...state,
      };
    }

    case DELETE_ORDER_ITEM_START_TYPE: {
      return {
        ...state,
        isDeletingOrder: true,
      };
    }

    case DELETE_ORDER_ITEM_SUCCESSFUL_TYPE: {
      // Need to find the Order item from the "data",
      // and replace it with the response
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const orderMap = createOrderFromAPIResponse(responseData);

        const existingOrderIndex = state.data.findIndex(
          (order) =>
            // @ts-ignore
            order.get("id") === responseData.id
        );

        state.data = state.data.update(existingOrderIndex, (value) =>
          // @ts-ignore
          value.merge(orderMap)
        );
      }

      return {
        ...state,
        isDeletingOrder: false,
      };
    }

    case DELETE_ORDER_ITEM_FAILED_TYPE: {
      return {
        ...state,
        isDeletingOrder: false,
      };
    }

    case DISPATCH_ORDER_TO_DRIVER_START_TYPE: {
      return {
        ...state,
        isDispatchingOrder: true,
      };
    }

    case DISPATCH_ORDER_TO_DRIVER_FAILED_TYPE: {
      return {
        ...state,
        isDispatchingOrder: false,
      };
    }

    case UPDATE_ORDER_START_TYPE: {
      return {
        ...state,
        isUpdatingOrder: true,
      };
    }

    case UPDATE_ORDER_FAILED_TYPE: {
      return {
        ...state,
        isUpdatingOrder: false,
      };
    }

    case COMPLETE_ORDER_TO_DRIVER_SUCCESSFUL_TYPE:
    case DISPATCH_ORDER_TO_DRIVER_SUCCESSFUL_TYPE:
    case UPDATE_ORDER_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const responseOrder = responseData.completedOrder || responseData;
        const responseOrderMap = createOrderFromAPIResponse(responseOrder);

        const existingOrderIndex = state.data.findIndex(
          (existingOrder) =>
            // @ts-ignore
            existingOrder.get("id") === responseOrderMap.get("id")
        );

        if (existingOrderIndex !== -1) {
          state.data = state.data.update(existingOrderIndex, (existingOrder) =>
            // @ts-ignore
            existingOrder.merge(responseOrderMap)
          );
        }
      }

      return {
        ...state,
        isUpdatingOrder: false,
        isDispatchingOrder: false,
      };
    }

    case REASSIGN_ORDER_TO_DRIVER_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const orderMap = createOrderFromAPIResponse(responseData);

        const existingOrderIndex = state.data.findIndex(
          (order) =>
            // @ts-ignore
            order.get("id") === responseData.id
        );

        state.data = state.data.update(existingOrderIndex, (value: any) =>
          value.merge(orderMap)
        );
      }

      return {
        ...state,
      };
    }

    case MARK_ORDER_COMPLETE_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const { completedOrder } = responseData;
        const orderMap = createOrderFromAPIResponse(completedOrder);

        const existingOrderIndex = state.data.findIndex(
          (order: any) => order.get("id") === completedOrder.id
        );

        state.data = state.data.update(existingOrderIndex, (value: any) =>
          value.merge(orderMap)
        );
      }

      return {
        ...state,
      };
    }

    case CANCEL_ORDER_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        const orderMap = createOrderFromAPIResponse(responseData);

        const existingOrderIndex = state.data.findIndex(
          (order) =>
            // @ts-ignore
            order.get("id") === responseData.id
        );

        state.data = state.data.update(existingOrderIndex, (value) =>
          // @ts-ignore
          value.merge(orderMap)
        );
      }

      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
