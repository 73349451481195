import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Close as CloseIcon, Textsms as MessageIcon } from "@material-ui/icons";
import { format, getTime, isToday } from "date-fns";
import isEmpty from "lodash.isempty";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Index,
  RowMouseEventHandlerParams,
  TableCellProps,
} from "react-virtualized";

import { sortTableWithKey, Table } from "../../../common";
import {
  actionSelectOrderRow,
  actionUnSelectAllOrders,
} from "../../Orders/actions";
import { actionUnSelectAllDrivers } from "../actions";
import { CancelDelivery, OrderMessages } from "../Dialogs";
import { OrdersDeliveredColumns } from "../helpers";
import styles from "../styles";

interface IOrdersCurrentlyDispatchedProps extends WithStyles<typeof styles> {}

function OrdersCurrentlyDispatched({
  classes,
}: IOrdersCurrentlyDispatchedProps) {
  const ordersActiveTableColumns = useRef(OrdersDeliveredColumns);
  const dispatch = useDispatch();

  const [showOrderMessagesDialog, setOrderMessagesDialog] = useState<{
    orderId: string;
  } | null>(null);

  const [showCancelOrderDialog, setCancelOrderDialog] = useState<{
    orderNumber: string;
    orderId: string;
  } | null>(null);

  useEffect(
    () => () => {
      dispatch(actionUnSelectAllOrders());
      dispatch(actionUnSelectAllDrivers());
    },
    [dispatch]
  );

  const availableDrivers = useSelector((state: any) => {
    const driversList = state.dispatch.driversData;

    if (driversList.size) {
      return driversList.filter(
        (account: any) => account.get("email") && account.get("isDriving")
      );
    }

    return driversList;
  });

  const deliveredOrders = useSelector((state: any) => {
    const ordersList = state.orders.data;
    const selectedAvailableDrivers = availableDrivers.filter((driver: any) =>
      driver.get("selected")
    );

    const selectedAvailableDriversIds: string[] = [];

    if (selectedAvailableDrivers.size) {
      selectedAvailableDrivers.forEach((driver: any) =>
        selectedAvailableDriversIds.push(driver.get("id"))
      );
    }

    return ordersList.filter((order: any) => {
      const isCancelled = order.get("status") === "Cancelled";
      const deliveredAt = order.get("deliveredAt");
      const isDeliveredToday = deliveredAt && isToday(new Date(deliveredAt));

      if (!isDeliveredToday || isCancelled) return false;

      if (selectedAvailableDrivers.size) {
        return selectedAvailableDriversIds.includes(
          order.getIn(["driver", "id"])
        );
      }

      return isDeliveredToday;
    });
  });

  const { sortedList: sortedOrders } = sortTableWithKey(
    deliveredOrders,
    "deliveredAt",
    "asc",
    (row, orderByKey) => getTime(new Date(row.get(orderByKey)))
  );

  /**
   *
   */
  function rowGetter({ index }: Index) {
    return sortedOrders.get(index);
  }

  /**
   *
   */
  function onRowClick({ rowData }: RowMouseEventHandlerParams) {
    dispatch(actionSelectOrderRow(rowData.get("id")));
  }

  /**
   *
   */
  const determineCellContent = ({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | JSX.Element[] | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    const rowId = rowData.get("id");
    const rowNumber = rowData.get("number");
    const isExternal = rowData.get("isExternal");

    if (dataKey === "selected") {
      const selected = rowData.get("selected");

      cellContent = (
        <Checkbox
          key={`active-${rowId}`}
          color="primary"
          checked={selected}
          className={classes.selectCheckbox}
        />
      );

      cellClass = classes.selectCheckboxCell;
    }

    if (dataKey === "number") {
      cellContent = (
        <Link
          to={{
            pathname: `/order/${rowId}/`,
            state: { order: rowData.toJS() },
          }}
        >
          {rowNumber}
        </Link>
      );
    }

    if (dataKey === "email") {
      cellContent = rowData.get("email");
    }

    if (dataKey === "driver") {
      cellContent = cellData.get("email");
    }

    if (dataKey === "address") {
      const address1 = rowData.getIn(["address", "address1"]);
      const address2 = rowData.getIn(["address", "address2"]);
      const majorIntersection = rowData.getIn(["address", "majorIntersection"]);
      const postalCode = rowData.getIn(["address", "postalCode"]);
      const city = rowData.getIn(["address", "city"]);
      const province = rowData.getIn(["address", "province"]);

      cellContent = (
        <CardContent className={classes.cardContent}>
          {address1}
          {address1 && (
            <>
              ,<br />
            </>
          )}

          {address2}
          {address2 && (
            <>
              ,<br />
            </>
          )}

          {majorIntersection}
          {majorIntersection && (
            <>
              ,<br />
            </>
          )}

          {postalCode}
          {postalCode && (
            <>
              ,<br />
            </>
          )}

          {city}
          {city && (
            <>
              ,<br />
            </>
          )}
          {province}
        </CardContent>
      );
    }

    if (dataKey === "products") {
      if (isEmpty(cellData)) {
        return {
          cellContent,
          cellClass,
        };
      }

      const returnValue: [string?] = [];

      // TODO - Figure out proper typeDef of product
      cellData.forEach((product: Map<string, string>) => {
        const name = product.get("name");
        const quantity = product.get("quantity");
        const size = product.get("size");

        returnValue.push(`${quantity} x ${name} - ${size}`);
      });

      // @ts-ignore
      cellContent = returnValue.map((product) => (
        <>
          {product}
          <br />
        </>
      ));
    }

    if (dataKey === "customer") {
      cellContent = (
        <CardContent className={classes.cardContent}>
          {`${
            rowData.getIn(["customer", "firstName"]) +
            " " +
            rowData.getIn(["customer", "lastName"])
          }`}

          {!!rowData.getIn(["customer", "phone"]) && (
            <>
              <Divider className={classes.cardContentDivider} />

              <b>Phone:</b>
              {" " + rowData.getIn(["customer", "phone"])}
              <br />
              <b>Email:</b>
              {" " + rowData.getIn(["customer", "emailDisplay"])}
            </>
          )}

          {!!rowData.getIn(["customer", "notes"]) && (
            <>
              <Divider className={classes.cardContentDivider} />

              {" " + rowData.getIn(["customer", "notes"])}
            </>
          )}
        </CardContent>
      );
    }

    if (dataKey === "actions") {
      const messages = rowData.get("messages");
      const containsMessages = messages && messages.size;

      let unseenMessagesCount = 0;

      if (messages && messages.size) {
        unseenMessagesCount = messages.filter(
          (message: any) => !message.get("seenByAdmin")
        ).size;
      }

      const hasLine = containsMessages && !isExternal;

      cellContent = (
        <>
          {containsMessages && (
            <Button
              size="small"
              disabled={false}
              disableElevation
              variant="contained"
              startIcon={<MessageIcon />}
              onClick={(e) => {
                e.stopPropagation();

                setOrderMessagesDialog({
                  orderId: rowId,
                });
              }}
            >
              {unseenMessagesCount
                ? `View (${unseenMessagesCount}) Messages`
                : `View Messages`}
            </Button>
          )}

          {hasLine && <br />}

          {!isExternal && (
            <Button
              size="small"
              disableElevation
              color="secondary"
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => {
                setCancelOrderDialog({
                  orderNumber: rowNumber,
                  orderId: rowId,
                });
              }}
            >
              Cancel Delivery
            </Button>
          )}
        </>
      );

      cellClass = classes.actionsCell;
    }

    if (dataKey === "receivedAt" || dataKey === "deliveredAt") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }


    if (dataKey === "status") {
      if (cellData === "Cancelled") {
        const cancelReason = rowData.get("cancelReason");

        cellContent = (
          <CardContent className={classes.cardContent}>
            {cellData}
  
            <Divider className={classes.cardContentDivider} />
  
            {cancelReason}
          </CardContent>
        );
      }

      if (cellData === "Claimed") {
        cellContent = (
          <CardContent 
            className={classes.cardContent} 
            style={{ 
              backgroundColor: '#31f431',
              height: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {cellData}
          </CardContent>
        );
      }
    }

    return {
      cellContent,
      cellClass,
    };
  };

  return (
    <>
      <Card
        square
        className={classes.currentlyDispatchedCard}
        style={{
          height:
            sortedOrders.size * 150 > 750
              ? "750px"
              : `${sortedOrders.size * 150 + 160}px`,
        }}
      >
        <CardHeader
          subheader="Delivered Today"
          className={classes.currentlyDispatchedCardHeader}
        />

        <Table
          renderEmptyHeader
          rowGetter={rowGetter}
          onRowClick={onRowClick}
          tableHeaderRowHeight={50}
          rowCount={sortedOrders.size}
          columns={ordersActiveTableColumns.current}
          determineCellContent={determineCellContent}
        />
      </Card>

      {!!showOrderMessagesDialog && (
        <OrderMessages
          viewOnly
          show={!!showOrderMessagesDialog}
          orderId={showOrderMessagesDialog?.orderId}
          onClose={() => setOrderMessagesDialog(null)}
        />
      )}

      <CancelDelivery
        show={showCancelOrderDialog}
        onClose={() => setCancelOrderDialog(null)}
      />
    </>
  );
}

export default withStyles(styles)(OrdersCurrentlyDispatched);
