import React from "react";
import {
  AppBar,
  Grid,
  Toolbar,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";

import styles from "./styles";

interface HeaderProps extends WithStyles<typeof styles> {}

function Header({ classes }: HeaderProps) {
  return (
    <>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Typography color="inherit" variant="h5" component="h1">
              Home
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default withStyles(styles)(Header);
