import React from "react";
import { Route, Switch } from "react-router-dom";

import OrdersActiveAndDeliveredToday from "./OrdersActiveAndDeliveredToday";
import DriversAvailabilityTable from "./DriversAvailabilityTable";
import DeliveredOrdersDashboard from "./OrdersDeliveredTable";

function DispatchDashboard() {
  return (
    <>
      <Switch>
        <Route
          path="/dispatch/delivered"
          component={DeliveredOrdersDashboard}
        />
        <Route path="/dispatch/drivers" component={DriversAvailabilityTable} />
        <Route path="/dispatch" component={OrdersActiveAndDeliveredToday} />
      </Switch>
    </>
  );
}

export default DispatchDashboard;
