import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Select,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import {
  Cached as ReorderIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { List, Map } from "immutable";
import isEmpty from "lodash.isempty";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Index, IndexRange, TableCellProps } from "react-virtualized";

import { ErrorSnackbarContext } from "../../App";
import { CancelDelivery } from "../Dispatch/Dialogs";
import { sortTableWithKey, Table } from "../../common";
import { ORDER_TYPE } from "../../common/constants";
import { styles } from "../Home/styles";
import {
  actionClearSearchOrders,
  actionFetchOrders,
  actionSearchOrders,
} from "./actions";
import { PastOrdersTableColumns } from "./helpers";

interface IPastOrdersProps extends WithStyles<typeof styles> {}

function PastOrders({ classes }: IPastOrdersProps) {
  const { toggleAlerts } = useContext(ErrorSnackbarContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const pastOrdersTableColumns = useRef(PastOrdersTableColumns);

  const [searchMode, setSearchMode] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [filterDatesRange, setFilterDatesRange] = useState<{
    fromDate?: string | null;
    toDate?: string | null;
  }>({
    fromDate: null,
    toDate: null,
  });

  const [showCancelOrderDialog, setCancelOrderDialog] = useState<{
    orderNumber: string;
    orderId: string;
  } | null>(null);

  const [selectedSearchCategory, setSelectedSearchCategory] = useState<string>(
    "phone"
  );

  const [searchDriver, setSearchDriver] = useState<string>("");
  const isDriverSearch = selectedSearchCategory === "driver";

  /**
   *
   */

  const orders: List<any> = useSelector((state: any) =>
    state.orders.data.filter((order: any) => !order.get("isActive"))
  );

  const isFetchingOrders = useSelector(
    (state: any) => state.orders.isFetchingOrders
  );

  const inActiveOrdersDataCursor = useSelector(
    (state: any) => state.orders.inActiveOrdersDataCursor
  );

  /**
   *
   */

  const searchedOrders: List<any> = useSelector((state: any) =>
    state.orders.searchedData.filter((order: any) => !order.get("isActive"))
  );

  const inActiveOrdersSearchDataCursor = useSelector(
    (state: any) => state.orders.inActiveOrdersSearchDataCursor
  );

  const isSearchingOrders = useSelector(
    (state: any) => state.orders.isSearchingOrders
  );

  let ordersToRender = orders;

  if (searchMode && searchedOrders.size) {
    ordersToRender = searchedOrders;
  }

  const { sortedList: sortedOrders } = sortTableWithKey(ordersToRender);

  const driverAccounts = useSelector((state: any) => {
    const driversList = state.dispatch.driversData;
    if (!driversList.size) return driversList;

    return driversList.filter(
      (account: any) => account.get("email") && account.get("isDriving")
    );
  });

  /**
   * When the container gets un-mounted, clear previously searched items.
   */
  useEffect(
    () => () => {
      dispatch(actionClearSearchOrders());
    },
    [dispatch]
  );

  // const remainingRows = searchedOrders.size
  //   ? inActiveOrdersSearchDataRemaining
  //   : inActiveOrdersDataRemaining;

  const remainingRows = 0;

  /**
   *
   */
  function rowGetter({ index }: Index) {
    return sortedOrders.get(index);
  }

  /**
   *
   */
  function isRowLoaded({ index }: Index) {
    return index < sortedOrders.size;
  }

  /**
   *
   */
  function loadMoreRows(params: IndexRange) {
    return isFetchingOrders || isSearchingOrders
      ? () => {}
      : searchMode
      ? dispatch(
          actionSearchOrders(
            searchTerm,
            selectedSearchCategory,
            filterDatesRange,
            ORDER_TYPE.Past,
            inActiveOrdersSearchDataCursor
          )
        )
      : dispatch(actionFetchOrders(ORDER_TYPE.Past, inActiveOrdersDataCursor));
  }

  /**
   *
   */
  function determineCellContent({
    cellData,
    dataKey,
    rowData,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } {
    let cellContent: JSX.Element | string = "";
    let cellClass: string = "";

    const rowId = rowData.get("id");
    const isCancelled = rowData.get("status") === "Cancelled";
    const isExternal = rowData.get("isExternal");

    if (dataKey === "number") {
      cellContent = (
        <Link
          to={{
            pathname: `/order/${rowId}/`,
            state: { order: rowData.toJS() },
          }}
        >
          {rowData.get("number")}
        </Link>
      );
    }

    if (dataKey === "category/type") {
      let category = rowData.get("category");
      if (category === "restaurant") {
        category = "variety";
      }
      const type = rowData.get("type");

      cellContent = (
        <CardContent className={classes.cardContent}>
          <b>Category:</b>
          {" " + category.charAt(0).toUpperCase() + category.slice(1)}

          <Divider className={classes.cardContentDivider} />

          <b>Type:</b>
          {" " + type.charAt(0).toUpperCase() + type.slice(1)}
        </CardContent>
      );
    }

    if (dataKey === "status") {
      if (cellData === "Cancelled") {
        const cancelReason = rowData.get("cancelReason");

        cellContent = (
          <CardContent className={classes.cardContent}>
            {cellData}
  
            <Divider className={classes.cardContentDivider} />
  
            {cancelReason}
          </CardContent>
        );
      }

      if (cellData === "Claimed") {
        cellContent = (
          <CardContent 
            className={classes.cardContent} 
            style={{ 
              backgroundColor: '#31f431',
              height: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {cellData}
          </CardContent>
        );
      }
    }

    if (dataKey === "products") {
      if (isEmpty(cellData)) {
        return {
          cellContent,
        };
      }

      const returnValue: [string?] = [];

      // TODO - Figure out proper typeDef of product
      cellData.forEach((product: Map<string, string>) => {
        const name = product.get("name");
        const quantity = product.get("quantity");
        const size = product.get("size");

        returnValue.push(`${quantity} x ${name} - ${size}`);
      });

      // @ts-ignore
      cellContent = returnValue.map((product) => (
        <>
          {product}
          <br />
        </>
      ));
    }

    if (dataKey === "customer") {
      cellContent = (
        <CardContent className={classes.cardContent}>
          {`${
            rowData.getIn(["customer", "firstName"]) +
            " " +
            rowData.getIn(["customer", "lastName"])
          }`}

          {!!rowData.getIn(["customer", "phone"]) && (
            <>
              <Divider className={classes.cardContentDivider} />

              <b>Phone:</b>
              {" " + rowData.getIn(["customer", "phone"])}
              <br />
              <b>Email:</b>
              {" " + rowData.getIn(["customer", "emailDisplay"])}
            </>
          )}

          {!!rowData.getIn(["customer", "notes"]) && (
            <>
              <Divider className={classes.cardContentDivider} />

              {" " + rowData.getIn(["customer", "notes"])}
            </>
          )}
        </CardContent>
      );
    }

    if (dataKey === "address") {
      const address1 = rowData.getIn(["address", "address1"]);
      const address2 = rowData.getIn(["address", "address2"]);
      const majorIntersection = rowData.getIn(["address", "majorIntersection"]);
      const postalCode = rowData.getIn(["address", "postalCode"]);
      const city = rowData.getIn(["address", "city"]);
      const province = rowData.getIn(["address", "province"]);

      cellContent = (
        <CardContent className={classes.cardContent}>
          {address1}
          {address1 && (
            <>
              ,<br />
            </>
          )}

          {address2}
          {address2 && (
            <>
              ,<br />
            </>
          )}

          {majorIntersection}
          {majorIntersection && (
            <>
              ,<br />
            </>
          )}

          {postalCode}
          {postalCode && (
            <>
              ,<br />
            </>
          )}

          {city}
          {city && (
            <>
              ,<br />
            </>
          )}
          {province}
        </CardContent>
      );
    }

    if (dataKey === "receivedAt") {
      cellContent = format(new Date(cellData), "MMM dd, yyyy, h:mm aaaa");
    }

    if (dataKey === "total") {
      cellContent = (
        <CardContent className={classes.cardContent}>
          <b>Sub Total:</b>
          {" " + rowData.get("subTotal")}

          <Divider className={classes.cardContentDivider} />

          <b>Grand Total:</b>
          {" " + rowData.get("grandTotal")}

          <br />
          <b>Delivery:</b>
          {" " + rowData.get("deliveryCharge")}

          {rowData.get("isExternal") && (
            <Fragment>
              <br />
              <b>Tip:</b>
              {" " + rowData.get("tip")}
            </Fragment>
          )}

          <br />
          <b>Tax:</b>
          {" " + rowData.get("tax")}

          <br />
          <b>Delivery Total:</b>
          {" " + rowData.get("deliveryTotal")}

          <br />
          <b>Payment Method:</b>
          {" " + rowData.getIn(["paymentMethod", "name"])}
        </CardContent>
      );
    }

    if (dataKey === "isDispatched") {
      const driver = rowData.get("driver");

      if (driver) {
        cellContent = (
          <>
            Dispatched
            <br />
            {driver.get("email")}
          </>
        );
      } else {
        cellContent = "Not dispatched";
      }
    }

    if (dataKey === "recalculate") {
      cellContent = <Button variant="outlined">Recalculate</Button>;
    }

    if (dataKey === "resendEmails") {
      cellContent = <Button variant="outlined">Resend Emails</Button>;
    }

    if (dataKey === "actions") {
      const hasLine = !isExternal && !isCancelled;

      cellContent = (
        <>
          {!isExternal && (
            <Button
              size="small"
              disableElevation
              variant="contained"
              startIcon={<ReorderIcon />}
              onClick={() =>
                history.push({
                  pathname: `/reorder/${rowId}`,
                  state: {
                    order: rowData.toJS(),
                  },
                })
              }
            >
              Reorder
            </Button>
          )}
          {hasLine && <br />}
          {!isCancelled && !isExternal && (
            <Button
              size="small"
              disableElevation
              color="secondary"
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => {
                setCancelOrderDialog({
                  orderNumber: rowData.get("number"),
                  orderId: rowId,
                });
              }}
            >
              Cancel Delivery
            </Button>
          )}
        </>
      );

      cellClass = classes.actionsCell;
    }

    return {
      cellContent,
      cellClass,
    };
  }

  /**
   *
   */
  function handleSearch() {
    setSearchMode(true);

    dispatch(
      actionSearchOrders(
        searchTerm,
        selectedSearchCategory,
        filterDatesRange,
        ORDER_TYPE.Past
      )
      // @ts-ignore
    ).then(
      (result: any) => {
        const { data } = result;
        toggleAlerts(
          `Found ${data.orders.length} order(s) for category ${selectedSearchCategory}.`
        );
      },
      (error: any) => {
        toggleAlerts("Error occurred while searching orders.", true);
      }
    );
  }

  /**
   *
   */
  function clearSearch() {
    setSearchTerm("");
    setSearchMode(false);
    setFilterDatesRange({
      toDate: null,
      fromDate: null,
    });

    dispatch(actionClearSearchOrders());
  }

  function renderTableHeader(showSearchAndFilters: boolean) {
    return (
      <>
        <Grid container spacing={2} alignItems="center">
          {showSearchAndFilters && (
            <Grid item lg={9}>
              <Grid container spacing={1} alignItems="center">
                <Grid item lg={2}>
                  {isDriverSearch ? (
                    <Select
                      native
                      disableUnderline
                      value={searchDriver}
                      onChange={({ target }) => {
                        setSearchDriver(target.value as string);
                        setSearchTerm(target.value as string);
                      }}
                    >
                      <option selected>Select a driver</option>
                      {driverAccounts.map((driver: any, index: number) => (
                        <option
                          value={driver.get("id")}
                          key={`${driver.get("id") + index}`}
                        >
                          {driver.get("email")}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      fullWidth
                      value={searchTerm}
                      onChange={({ target }) =>
                        setSearchTerm(target.value as string)
                      }
                      placeholder="Search term"
                      InputProps={{
                        disableUnderline: true,
                        "aria-label": "search",
                      }}
                    />
                  )}
                </Grid>

                <Grid item lg={2}>
                  <Select
                    native
                    disableUnderline
                    value={selectedSearchCategory}
                    fullWidth
                    onChange={({ target }) => {
                      // Driver search uses ID (as search term )and should be cleared when changing filters to or from
                      if (isDriverSearch || target.value === "driver") {
                        setSearchTerm("");
                      }

                      setSelectedSearchCategory(target.value as string);
                    }}
                  >
                    <option selected value="phone">
                      Phone
                    </option>
                    <option value="number">Order Number</option>
                    <option value="address">Address</option>
                    <option value="driver">Driver</option>
                    <option value="other">Other</option>
                  </Select>
                </Grid>

                <Grid item lg={2}>
                  <KeyboardDatePicker
                    disableFuture
                    disableToolbar
                    format="MM/dd/yyyy"
                    variant="inline"
                    value={filterDatesRange.fromDate}
                    placeholder="From date"
                    onChange={(_, value) =>
                      setFilterDatesRange((currentValues) => ({
                        ...currentValues,
                        fromDate: value,
                      }))
                    }
                    InputProps={{
                      disableUnderline: true,
                      "aria-label": "filterFrom",
                    }}
                  />
                </Grid>

                <Grid item lg={2}>
                  <KeyboardDatePicker
                    disableFuture
                    disableToolbar
                    format="MM/dd/yyyy"
                    variant="inline"
                    value={filterDatesRange.toDate}
                    placeholder="To date"
                    onChange={(_, value) =>
                      setFilterDatesRange((currentValues) => ({
                        ...currentValues,
                        toDate: value,
                      }))
                    }
                    InputProps={{
                      disableUnderline: true,
                      "aria-label": "filterTo",
                    }}
                  />
                </Grid>

                <Grid item>
                  <Button
                    disableElevation
                    variant="contained"
                    startIcon={<SearchIcon />}
                    size="small"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Grid>

                {!!searchedOrders.size && (
                  <Grid item className={classes.clearButton}>
                    <Button
                      disableElevation
                      variant="contained"
                      startIcon={<ClearIcon />}
                      size="small"
                      onClick={clearSearch}
                    >
                      Clear Search
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  }

  return (
    <Card square elevation={0} className={classes.pastOrdersCard}>
      <CardHeader
        subheader="Past Orders"
        className={classes.pastOrdersCardHeader}
      />

      <Table
        renderEmptyHeader={false}
        rowGetter={rowGetter}
        tableHeaderRowHeight={50}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={sortedOrders.size}
        remainingRows={remainingRows}
        tableHeader={renderTableHeader(true)}
        columns={pastOrdersTableColumns.current}
        determineCellContent={determineCellContent}
        isLoading={isFetchingOrders || isSearchingOrders}
      />

      <CancelDelivery
        show={showCancelOrderDialog}
        onClose={() => setCancelOrderDialog(null)}
      />
    </Card>
  );
}

export default withStyles(styles)(PastOrders);
