import isEmpty from "lodash.isempty";
import React, { useContext, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ErrorSnackbarContext } from "../../App";
import { actionAddAddress } from "../../store/Addresses/actions";
import Address from "./Address";

interface IAddAddressProps {
  accountId?: string;
  closeDialog?: () => void;
}

const AddAddress = forwardRef(
  ({ accountId, closeDialog }: IAddAddressProps, ref) => {
    const { toggleAlerts } = useContext(ErrorSnackbarContext);
    const dispatch = useDispatch();

    const accountToEdit = useSelector(({ accounts }: any) => {
      let account;

      const matchAccountId = (account: any) => account.get("id") === accountId;

      if (accounts.searchedData.size) {
        account = accounts.searchedData.find(matchAccountId);
      }

      if (isEmpty(account)) {
        account = accounts.data.find(matchAccountId);
      }

      return account;
    });

    const initialValues = {
      customer: accountToEdit && accountToEdit.toJS(),
      address1: "",
      address2: "",
      buzzerCode: "",
      city: "",
      postalCode: "",
      province: "",
      phone: "",
      phoneExt: "",
    };

    function onSubmit(address: any, accountId: string) {
      dispatch(actionAddAddress(address, accountId))
        // @ts-ignore
        .then(
          () => {
            toggleAlerts("Successfully added the address.", false);
            closeDialog && closeDialog();
          },
          (error: any) => {
            toggleAlerts("Error occurred while adding the address.", true);
          }
        );
    }

    return (
      <Address
        ref={ref}
        onSubmit={onSubmit}
        account={accountToEdit}
        initialValues={initialValues}
      />
    );
  }
);

export default AddAddress;
