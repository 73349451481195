import {
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  withStyles,
  WithStyles,
  IconButton,
} from "@material-ui/core";
import { Clear as ClearIcon, Search as SearchIcon, Edit as EditIcon } from "@material-ui/icons";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableCellProps } from "react-virtualized";
import { IProduct } from "./ProductDialog"; // Adjust the path as necessary
import { Map as ImmutableMap } from "immutable";

import { Table } from "../../common";
import { actionSearchProducts } from "../../store/Products/actions";
import { actionClearSearchProducts, actionFetchAllProducts } from "./actions";
import { ProductsTableColumns } from "./helpers";
import styles from "./styles";
import ProductDialog from "./ProductDialog";

interface IProductsDashboardProps extends WithStyles<typeof styles> {}

function ProductsDashboard({ classes }: IProductsDashboardProps) {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const productsTableColumns = useRef([
    ...ProductsTableColumns,
    {
      label: "Actions",
      dataKey: "actions",
      width: 100,
      cellRenderer: ({ rowData }: { rowData: ImmutableMap<string, any> }) => (
        <IconButton onClick={() => handleEdit(rowData)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ]);

  useEffect(() => {
    dispatch(actionFetchAllProducts());
  }, [dispatch]);

  const handleCreate = () => {
    setSelectedProduct(undefined);
    setShowDialog(true);
  };

  const handleEdit = (product: ImmutableMap<string, any>) => {
    const productObj: IProduct = {
      id: product.get("id"),
      product_name: product.get("name"),
      company: product.get("company"),
      type: product.get("type"),
      size: product.get("size"),
      producer: product.get("producer"),
    };
    setSelectedProduct(productObj);
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const products = useSelector((state: any) => state.products.data);

  const searchedProducts = useSelector(
    (state: any) => state.products.searchedData
  );

  const productsToRender = searchedProducts.size ? searchedProducts : products;

  const _memoizedRowGetter = useCallback(
    ({ index }) => productsToRender.get(index),
    [productsToRender]
  );

  const _memoizedClearSearch = useCallback(() => {
    setSearchTerm("");
    dispatch(actionClearSearchProducts());
  }, [dispatch]);

  const _memoizedHandleSearch = useCallback(() => {
    dispatch(actionSearchProducts(searchTerm));
  }, [searchTerm, dispatch]);

  const determineCellContent = ({
    cellData,
    dataKey,
  }: TableCellProps): {
    cellContent: JSX.Element | string;
    cellClass?: string;
  } => {
    let cellContent: JSX.Element | string = "";
    let cellClass;

    if (dataKey === "company") {
      cellContent = cellData.replace(/-/gi, " ").toUpperCase();

      cellClass = classes.companyCell;
    }

    return {
      cellContent,
      cellClass,
    };
  };

  const renderTableHeader = (showSearchAndFilters: boolean) => (
    <>
      <Grid container spacing={2} alignItems="center">
        {showSearchAndFilters && (
          <>
            <Grid item lg={3}>
              <TextField
                fullWidth
                value={searchTerm}
                onChange={({ target }) => setSearchTerm(target.value)}
                placeholder="Search by product name and producer"
                InputProps={{
                  disableUnderline: true,
                  "aria-label": "search",
                }}
              />
            </Grid>

            <Grid item>
              <Button
                disableElevation
                variant="contained"
                startIcon={<SearchIcon />}
                size="small"
                onClick={_memoizedHandleSearch}
              >
                Search
              </Button>
            </Grid>

            {!!searchedProducts.size && (
              <Grid item>
                <Button
                  disableElevation
                  variant="contained"
                  startIcon={<ClearIcon />}
                  size="small"
                  onClick={_memoizedClearSearch}
                >
                  Clear Search
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );

  return (
    <>
      <Card square className={classes.productsCard}>
        <CardHeader
          subheader="Products"
          className={classes.productsCardHeader}
          action={
            <Button
              color="primary"
              variant="contained"
              onClick={handleCreate}
            >
              Create Product
            </Button>
          }
        />
        <Table
          renderEmptyHeader
          tableRowHeight={65}
          rowCount={productsToRender.size}
          tableHeaderRowHeight={50}
          rowGetter={_memoizedRowGetter}
          columns={productsTableColumns.current}
          tableHeader={renderTableHeader(true)}
          determineCellContent={determineCellContent}
        />
      </Card>
      {showDialog && (
        <ProductDialog
          show={showDialog}
          product={selectedProduct}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default withStyles(styles)(ProductsDashboard);