import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { ErrorSnackbarContext } from "../../../App";
import { actionCancelDelivery } from "../../Orders/actions";
import { actionFetchDispatchDrivers } from "../actions";
import styles from "../styles";

interface ICancelDeliveryProps extends WithStyles<typeof styles> {
  show: { orderNumber: string; orderId: string } | null;
  onClose: () => void;
}

const CancelDeliverySchema = yup.object().shape({
  cancelReason: yup.string().required("Required"),
});

function CancelDelivery({ show, onClose, classes }: ICancelDeliveryProps) {
  const dispatch = useDispatch();
  let orderId: string | null = null;
  let orderNumber: string | null = null;

  if (show) {
    ({ orderNumber, orderId } = show);
  }

  return (
    <ErrorSnackbarContext.Consumer>
      {({ toggleAlerts }) => (
        <Dialog open={!!show} onClose={onClose}>
          <Formik
            key="CancelDelivery"
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={CancelDeliverySchema}
            enableReinitialize
            initialValues={{
              cancelReason: "",
            }}
            onSubmit={({ cancelReason }) => {
              if (orderId) {
                dispatch(actionCancelDelivery(orderId, cancelReason))
                  // @ts-ignore
                  .then(
                    () => {
                      dispatch(actionFetchDispatchDrivers());
                      toggleAlerts("Successfully cancelled the delivery.");
                      onClose();
                    },
                    () => {
                      toggleAlerts(
                        "Error occurred while cancelling the delivery.",
                        true
                      );
                    }
                  );
              }
            }}
          >
            {({ errors, values, handleSubmit, handleChange }) => {
              return (
                <>
                  <DialogTitle>
                    Cancel Delivery of Order #{orderNumber}
                  </DialogTitle>

                  <Divider />

                  <DialogContent
                    classes={{
                      root: classes.reassignDialog,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Reason"
                          placeholder="Reason"
                          variant="outlined"
                          name="cancelReason"
                          error={!!errors.cancelReason}
                          helperText={errors.cancelReason}
                          defaultValue={values.cancelReason}
                          value={values.cancelReason}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <Divider />

                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onClose()}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        </Dialog>
      )}
    </ErrorSnackbarContext.Consumer>
  );
}

export default withStyles(styles)(CancelDelivery);
