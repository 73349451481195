import { Divider } from "@material-ui/core";
import React from "react";

import TableHead from "./TableHead";
import TableBody, { TableBodyProps } from "./TableBody";
import EmptyTableBody from "./EmptyTableBody";
import EmptyLoadingTableBody from "./EmptyLoadingTableBody";

interface TableProps extends Omit<TableBodyProps, "classes"> {
  isLoading?: boolean;
  tableHeader?: JSX.Element;
  renderEmptyHeader?: boolean;
}

/**
 *
 */
const Table = React.memo((props: TableProps) => {
  const { tableHeader, isLoading, renderEmptyHeader, ...otherProps } = props;
  const rowCount = otherProps.rowCount;

  if (!rowCount && renderEmptyHeader) {
    return (
      <>
        <Divider light />

        {isLoading ? <EmptyLoadingTableBody /> : <EmptyTableBody />}
      </>
    );
  }

  return (
    <>
      {tableHeader && <TableHead tableHeader={tableHeader} />}

      <Divider light />

      <TableBody {...otherProps} hasTableHeader={!!tableHeader} />
    </>
  );
});

export default Table;
