import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      marginBottom: theme.spacing(2),
    },
    blockTableCell: {
      alignItems: "flex-start",
    },
    notesCell: {
      alignItems: "center",
      overflowY: "scroll",
    },
    accountsCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    accountsCardHeader: {
      backgroundColor: "white",
    },
    companyCell: {
      textTransform: "capitalize",
    },
    deactivateAccountEmail: {
      marginTop: theme.spacing(),
    },
    deactivateAccountName: {
      marginTop: theme.spacing(2),
      fontWeight: "bold",
    },
  });

export default styles;
