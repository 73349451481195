import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { FormikHandlers } from "formik";
import React, { createRef } from "react";

import AddAddress from "../AddAddress";

interface IAddAddressDialogProps {
  show: boolean;
  accountId?: string;
  onClose: () => void;
}

function AddAddressDialog({
  accountId,
  show,
  onClose,
}: IAddAddressDialogProps) {
  const ref = createRef<FormikHandlers>();

  function handleChildFormSubmit() {
    // This ref holds a reference of the Address form which get
    // renders as a child. If it holds a valid Formik form instance, then
    // trigger it's handleSubmit.

    if (ref && ref.current && ref.current.handleSubmit) {
      ref.current.handleSubmit();
    }
  }

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>Add an address</DialogTitle>

      <Divider />

      <DialogContent>
        <AddAddress
          ref={ref}
          accountId={accountId}
          closeDialog={() => onClose()}
        />
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button color="secondary" variant="contained" onClick={() => onClose()}>
          Close
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleChildFormSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddAddressDialog;
