import { createStyles, Theme } from "@material-ui/core";

const drawerWidth = 220;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    app: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    toolBar: {
      justifyContent: "flex-end",
    },
    main: {
      flex: 1,
      padding: theme.spacing(2, 2, 0, 2),
    },
    footer: {
      padding: theme.spacing(2),
    },
    selectCheckbox: {
      padding: 0,
    },
    selectCheckboxCell: {
      paddingTop: "12px",
      alignItems: "center",
    },
    actionsCell: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingRight: theme.spacing(2),
    },
    dispatchDropdown: {
      marginRight: theme.spacing(2),
    },
    dispatchButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    clearButton: {
      marginLeft: theme.spacing(1),
    },
    cardContent: {
      padding: "0 !important",
      alignItems: "center",
      width: "100%",
    },
    cardContentDivider: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
    },
    tableTitle: {},
    titleRoot: {
      display: "flex",
      alignItems: "center",
    },
    filterInput: {
      fontSize: theme.typography.fontSize,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    divider: {
      height: 28,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    textInput: {
      fontSize: theme.typography.fontSize,
    },
    mainCard: {
      marginBottom: theme.spacing(2),
    },
    headerButton: {
      marginLeft: theme.spacing(1),
    },
    recentOrderCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
    },
    recentOrderCardHeader: {
      backgroundColor: "white",
    },
    pastOrdersCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
    },
    pastOrdersCardHeader: {
      backgroundColor: "white",
    },
  });

export { styles, drawerWidth };
