import React from "react";

import DriversAvailable from "./DriversAvailable";
import ActiveOrders from "./OrdersActive";
import DeliveredOrders from "./OrdersDeliveredToday";

function OrdersActiveAndDeliveredToday() {
  return (
    <>
      <DriversAvailable />
      <ActiveOrders />
      <DeliveredOrders />
    </>
  );
}

export default OrdersActiveAndDeliveredToday;
