import { apiInstance } from "../../config";

const FETCH_PAYMENT_METHODS_START_TYPE = "FETCH_PAYMENT_METHODS_START";
const FETCH_PAYMENT_METHODS_SUCCESSFUL_TYPE =
  "FETCH_PAYMENT_METHODS_SUCCESSFUL";
const FETCH_PAYMENT_METHODS_FAILED_TYPE = "FETCH_PAYMENT_METHODS_FAILED";

/**
 *
 */
function actionFetchPaymentMethods() {
  return {
    types: [
      FETCH_PAYMENT_METHODS_START_TYPE,
      FETCH_PAYMENT_METHODS_SUCCESSFUL_TYPE,
      FETCH_PAYMENT_METHODS_FAILED_TYPE,
    ],
    promise: apiInstance.get("/payment-methods"),
  };
}

export {
  actionFetchPaymentMethods,
  FETCH_PAYMENT_METHODS_START_TYPE,
  FETCH_PAYMENT_METHODS_SUCCESSFUL_TYPE,
  FETCH_PAYMENT_METHODS_FAILED_TYPE,
};
