import { List } from "immutable";

function sortTableWithKey(
  list: List<any>,
  sortKey: string = "number",
  sortOrder: string = "desc",
  sortByFn?: (row: any, orderByKey: string) => number
): {
  sortedList: List<any>;
} {
  let sortedList = list;

  if (sortByFn) {
    sortedList = sortedList.sortBy((row) => sortByFn(row, sortKey));
  } else {
    sortedList = sortedList.sortBy((row) => parseInt(row.get(sortKey)));
  }

  if (sortOrder === "desc") {
    sortedList = sortedList.reverse();
  }

  return { sortedList };
}

export { sortTableWithKey };
