import { Card, CardHeader, withStyles, WithStyles } from "@material-ui/core";
import React from "react";

import styles from "./styles";

interface IEmptyLoadingTableBodyProps extends WithStyles<typeof styles> {}

const EmptyLoadingTableBody = React.memo(
  ({ classes }: IEmptyLoadingTableBodyProps) => (
    <Card square className={classes.emptyTableReportCard}>
      <CardHeader
        subheader={"Loading records"}
        className={classes.emptyTableReportCardHeader}
      />
    </Card>
  )
);

export default withStyles(styles)(EmptyLoadingTableBody);
