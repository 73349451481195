import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import isEmpty from "lodash.isempty";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { ErrorSnackbarContext } from "../../../App";
import { actionEditAccount } from "../../../store/Accounts/actions";

interface IEditAccountProps {
  show: boolean;
  accountId: any;
  onClose: () => void;
}

const EditAccountSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  admin: yup.bool(),
  driver: yup.bool(),
  phone: yup.string().nullable(),
  phoneExt: yup.string().nullable(),
  email: yup.string().nullable(),
  notes: yup.string().nullable(),
});

function EditAccount({ accountId, show, onClose }: IEditAccountProps) {
  const dispatch = useDispatch();

  const accountToEdit = useSelector(({ accounts }: any) => {
    let account;

    const matchAccountId = (account: any) => account.get("id") === accountId;

    if (accounts.searchedData.size) {
      account = accounts.searchedData.find(matchAccountId);
    }

    if (isEmpty(account)) {
      account = accounts.data.find(matchAccountId);
    }

    return account;
  });

  if (!accountToEdit) {
    return null;
  }

  return (
    <Dialog open={show} onClose={onClose}>
      <ErrorSnackbarContext.Consumer>
        {({ toggleAlerts }) => (
          <Formik
            key="EditAccount"
            validationSchema={EditAccountSchema}
            validateOnChange={false}
            enableReinitialize
            initialValues={{
              admin: accountToEdit.get("admin"),
              driver: accountToEdit.get("driver"),
              firstName: accountToEdit.get("firstName"),
              lastName: accountToEdit.get("lastName"),
              email: accountToEdit.get("email"),
              phone: accountToEdit.get("phone"),
              phoneExt: accountToEdit.get("phoneExt"),
              notes: accountToEdit.get("notes"),
            }}
            onSubmit={async (values) => {
              await dispatch(actionEditAccount(accountToEdit.get("id"), values))
                // @ts-ignore
                .then(
                  () => {
                    toggleAlerts("Successfully updated the account.", false);
                    onClose();
                  },
                  () => {
                    toggleAlerts(
                      "Error occurred while updating the account.",
                      true
                    );
                  }
                );
            }}
          >
            {({
              errors,
              values,
              handleSubmit,
              handleChange,
            }) => {
              return (
                <>
                  <DialogTitle>Edit user account</DialogTitle>

                  <Divider />

                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Email"
                          placeholder="Email"
                          variant="outlined"
                          name="email"
                          error={!!errors.email}
                          helperText={errors.email}
                          defaultValue={values.email}
                          value={values.email}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="First Name"
                          placeholder="First Name"
                          variant="outlined"
                          name="firstName"
                          error={!!errors.firstName}
                          helperText={errors.firstName}
                          defaultValue={values.firstName}
                          value={values.firstName}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Last Name"
                          placeholder="Last Name"
                          variant="outlined"
                          name="lastName"
                          error={!!errors.lastName}
                          helperText={errors.lastName}
                          defaultValue={values.lastName}
                          value={values.lastName}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <FormControlLabel
                          label="Admin"
                          control={
                            <Checkbox
                              checked={values.admin}
                              onChange={handleChange}
                              name="admin"
                            />
                          }
                        />

                        <FormControlLabel
                          label="Driver"
                          control={
                            <Checkbox
                              checked={values.driver}
                              onChange={handleChange}
                              name="driver"
                            />
                          }
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Phone"
                          placeholder="Phone"
                          variant="outlined"
                          name="phone"
                          error={!!errors.phone}
                          helperText={errors.phone}
                          value={values.phone}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          label="Phone Ext"
                          size="small"
                          placeholder="Phone Ext"
                          variant="outlined"
                          name="phoneExt"
                          value={values.phoneExt}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          label="Notes"
                          placeholder="Notes"
                          variant="outlined"
                          name="notes"
                          value={values.notes}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <Divider />

                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onClose()}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        )}
      </ErrorSnackbarContext.Consumer>
    </Dialog>
  );
}

export default EditAccount;
