import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { FormikProps } from "formik";
import React, { createRef } from "react";
import CreateOrEditProduct from "./CreateOrEditProduct";

export interface IProduct {
  id?: string;
  product_name: string;
  company: string;
  type?: string;
  size?: string;
  producer?: string;
}

interface IEditProductDialogProps {
  show: boolean;
  product?: IProduct;
  onClose: () => void;
}

function EditProductDialog({ product, show, onClose }: IEditProductDialogProps) {
  const ref = createRef<FormikProps<any>>();

  function handleChildFormSubmit() {
    if (ref && ref.current && ref.current.handleSubmit) {
      ref.current.handleSubmit();
    }
  }

  return (
    <Dialog open={show} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{product ? "Edit Product" : "Create Product"}</DialogTitle>
      <Divider />
      <DialogContent>
        <CreateOrEditProduct ref={ref} product={product} closeDialog={onClose} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={onClose}>
          Close
        </Button>
        <Button color="primary" variant="contained" onClick={handleChildFormSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProductDialog;