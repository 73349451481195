import { ORDER_TYPE } from "../../common/constants";
import { apiInstance } from "../../config";

const FETCH_ORDERS_START_TYPE = "FETCH_ORDERS_START";
const FETCH_ORDERS_SUCCESSFUL_TYPE = "FETCH_ORDERS_SUCCESSFUL";
const FETCH_ORDERS_FAILED_TYPE = "FETCH_ORDERS_FAILED";

/**
 *
 */
function actionFetchOrders(
  orders_type: ORDER_TYPE,
  cursor?: string,
  per_page?: number | string,
  datesRange?: {
    fromDate?: string | null;
    toDate?: string | null;
  }
) {
  return {
    types: [
      FETCH_ORDERS_START_TYPE,
      FETCH_ORDERS_SUCCESSFUL_TYPE,
      FETCH_ORDERS_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/admin/order`, {
      params: {
        cursor,
        orders_type,
        per_page,
        start_range: datesRange?.fromDate,
        end_range: datesRange?.toDate,
      },
    }),
    payload: {
      orders_type,
    },
  };
}

const FETCH_ORDER_MESSAGES_START_TYPE = "FETCH_ORDER_MESSAGES_START";
const FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE = "FETCH_ORDER_MESSAGES_SUCCESSFUL";
const FETCH_ORDER_MESSAGES_FAILED_TYPE = "FETCH_ORDER_MESSAGES_FAILED";

/**
 *
 */
function actionFetchOrderMessages(orderId: string) {
  return {
    types: [
      FETCH_ORDER_MESSAGES_START_TYPE,
      FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE,
      FETCH_ORDER_MESSAGES_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/admin/dispatch/message`, {
      params: {
        orderId,
      },
    }),
    payload: {
      orderId,
    },
  };
}

const POST_ORDER_MESSAGE_START_TYPE = "POST_ORDER_MESSAGE_START";
const POST_ORDER_MESSAGE_SUCCESSFUL_TYPE = "POST_ORDER_MESSAGE_SUCCESSFUL";
const POST_ORDER_MESSAGE_FAILED_TYPE = "POST_ORDER_MESSAGE_FAILED";

/**
 *
 */
function actionPostOrderMessage(orderId: string, message: string) {
  return {
    types: [
      POST_ORDER_MESSAGE_START_TYPE,
      POST_ORDER_MESSAGE_SUCCESSFUL_TYPE,
      POST_ORDER_MESSAGE_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/admin/dispatch/message`, {
      orderId,
      message,
    }),
    payload: {
      orderId,
    },
  };
}

const SEARCH_ORDERS_START_TYPE = "SEARCH_ORDERS_START";
const SEARCH_ORDERS_SUCCESSFUL_TYPE = "SEARCH_ORDERS_SUCCESSFUL";
const SEARCH_ORDERS_FAILED_TYPE = "SEARCH_ORDERS_FAILED";

/**
 *
 */
function actionSearchOrders(
  searchTerm: string,
  searchCategory: string,
  datesRange?: {
    fromDate?: string | null;
    toDate?: string | null;
  },
  orders_type?: ORDER_TYPE,
  cursor?: string
) {
  return {
    types: [
      SEARCH_ORDERS_START_TYPE,
      SEARCH_ORDERS_SUCCESSFUL_TYPE,
      SEARCH_ORDERS_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/admin/order`, {
      params: {
        cursor,
        searchTerm,
        searchCategory,
        orders_type,
        start_range: datesRange?.fromDate,
        end_range: datesRange?.toDate,
      },
    }),
  };
}

const CLEAR_SEARCH_ORDER_TYPE = "CLEAR_SEARCH_ORDERS";

/**
 *
 */
function actionClearSearchOrders() {
  return {
    type: CLEAR_SEARCH_ORDER_TYPE,
  };
}

const SELECT_ORDER_ROW_TYPE = "SELECT_ORDER_ROW";

/**
 *
 */
function actionSelectOrderRow(orderID: number) {
  return {
    type: SELECT_ORDER_ROW_TYPE,
    payload: {
      orderID,
    },
  };
}

const UNSELECT_ALL_ORDERS_ROW_TYPE = "UNSELECT_ALL_ORDERS_ROW";

/**
 *
 */
function actionUnSelectAllOrders() {
  return {
    type: UNSELECT_ALL_ORDERS_ROW_TYPE,
  };
}

const DISPATCH_ORDER_TO_DRIVER_START_TYPE = "DISPATCH_ORDER_TO_DRIVER_START";
const DISPATCH_ORDER_TO_DRIVER_SUCCESSFUL_TYPE =
  "DISPATCH_ORDER_TO_DRIVER_SUCCESSFUL";
const DISPATCH_ORDER_TO_DRIVER_FAILED_TYPE = "DISPATCH_ORDER_TO_DRIVER_FAILED";

/**
 *
 */
function actionDispatchOrderToDriver(orderId: string, driverId: string) {
  return {
    types: [
      DISPATCH_ORDER_TO_DRIVER_START_TYPE,
      DISPATCH_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
      DISPATCH_ORDER_TO_DRIVER_FAILED_TYPE,
    ],
    promise: apiInstance.patch("/admin/dispatch", {
      orderId,
      driverId,
    }),
  };
}

const COMPLETE_ORDER_TO_DRIVER_START_TYPE = "COMPLETE_ORDER_TO_DRIVER_START";
const COMPLETE_ORDER_TO_DRIVER_SUCCESSFUL_TYPE =
  "COMPLETE_ORDER_TO_DRIVER_SUCCESSFUL";
const COMPLETE_ORDER_TO_DRIVER_FAILED_TYPE = "COMPLETE_ORDER_TO_DRIVER_FAILED";

/**
 *
 */
function actionCompleteOrderForDriver(orderId: string, driverId: string) {
  return {
    types: [
      COMPLETE_ORDER_TO_DRIVER_START_TYPE,
      COMPLETE_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
      COMPLETE_ORDER_TO_DRIVER_FAILED_TYPE,
    ],
    promise: apiInstance.patch("/driver/dispatch/complete_order", {
      orderId,
      driverId,
    }),
  };
}

const DELETE_ORDER_ITEM_START_TYPE = "DELETE_ORDER_ITEM_START";
const DELETE_ORDER_ITEM_SUCCESSFUL_TYPE = "DELETE_ORDER_ITEM_SUCCESSFUL";
const DELETE_ORDER_ITEM_FAILED_TYPE = "DELETE_ORDER_ITEM_FAILED";

/**
 *
 */
function actionDeleteOrderItem(order: any, productId: string) {
  const parsedOrder = order.toJS();

  return {
    types: [
      DELETE_ORDER_ITEM_START_TYPE,
      DELETE_ORDER_ITEM_SUCCESSFUL_TYPE,
      DELETE_ORDER_ITEM_FAILED_TYPE,
    ],
    promise: apiInstance.patch(`/admin/order`, {
      orderId: parsedOrder.id,
      varietyItems: parsedOrder.varietyItems,
      paymentMethodId: parsedOrder.paymentMethodId,
      products: parsedOrder.products.filter(
        (product: any) => product.id !== productId
      ),
    }),
  };
}

const UPDATE_ORDER_START_TYPE = "UPDATE_ORDER_START";
const UPDATE_ORDER_SUCCESSFUL_TYPE = "UPDATE_ORDER_SUCCESSFUL";
const UPDATE_ORDER_FAILED_TYPE = "UPDATE_ORDER_FAILED";

/**
 *
 */
function actionUpdateOrder(order: any) {
  return {
    types: [
      UPDATE_ORDER_START_TYPE,
      UPDATE_ORDER_SUCCESSFUL_TYPE,
      UPDATE_ORDER_FAILED_TYPE,
    ],
    promise: apiInstance.patch(`/admin/order`, {
      ...order,
    }),
  };
}

const RE_ORDER_START_TYPE = "RE_ORDER_START";
const RE_ORDER_SUCCESSFUL_TYPE = "RE_ORDER_SUCCESSFUL";
const RE_ORDER_FAILED_TYPE = "RE_ORDER_FAILED";

/**
 *
 */
function actionReorder(order: any) {
  return {
    types: [
      RE_ORDER_START_TYPE,
      RE_ORDER_SUCCESSFUL_TYPE,
      RE_ORDER_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/order`, {
      ...order,
      isReorder: true,
    }),
  };
}

const CREATE_ORDER_START_TYPE = "CREATE_ORDER_START";
const CREATE_ORDER_SUCCESSFUL_TYPE = "CREATE_ORDER_SUCCESSFUL";
const CREATE_ORDER_FAILED_TYPE = "CREATE_ORDER_FAILED";

/**
 *
 */
function actionCreateOrder(order: any) {
  return {
    types: [
      CREATE_ORDER_START_TYPE,
      CREATE_ORDER_SUCCESSFUL_TYPE,
      CREATE_ORDER_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/order`, {
      ...order,
    }),
  };
}

const CANCEL_ORDER_START_TYPE = "CANCEL_ORDER_START";
const CANCEL_ORDER_SUCCESSFUL_TYPE = "CANCEL_ORDER_SUCCESSFUL";
const CANCEL_ORDER_FAILED_TYPE = "CANCEL_ORDER_FAILED";

/**
 *
 */
function actionCancelDelivery(orderId: string, cancelReason: string) {
  return {
    types: [
      CANCEL_ORDER_START_TYPE,
      CANCEL_ORDER_SUCCESSFUL_TYPE,
      CANCEL_ORDER_FAILED_TYPE,
    ],
    promise: apiInstance.patch(`/admin/dispatch/cancel`, {
      orderId,
      cancelReason,
    }),
  };
}

const CALCULATE_ORDER_CHARGES_START_TYPE = "CALCULATE_ORDER_CHARGES_START";
const CALCULATE_ORDER_CHARGES_SUCCESSFUL_TYPE =
  "CALCULATE_ORDER_CHARGES_SUCCESSFUL";
const CALCULATE_ORDER_CHARGES_FAILED_TYPE = "CALCULATE_ORDER_CHARGES_FAILED";

/**
 *
 */
function actionCalculateOrderCharges(order: any) {
  return {
    types: [
      CALCULATE_ORDER_CHARGES_START_TYPE,
      CALCULATE_ORDER_CHARGES_SUCCESSFUL_TYPE,
      CALCULATE_ORDER_CHARGES_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/order/calculate_charges`, {
      ...order,
    }),
  };
}

export {
  actionCreateOrder,
  actionCancelDelivery,
  actionCalculateOrderCharges,
  actionCompleteOrderForDriver,
  actionDispatchOrderToDriver,
  actionDeleteOrderItem,
  actionFetchOrders,
  actionSearchOrders,
  actionClearSearchOrders,
  actionSelectOrderRow,
  actionUnSelectAllOrders,
  actionUpdateOrder,
  actionReorder,
  actionFetchOrderMessages,
  actionPostOrderMessage,
  CALCULATE_ORDER_CHARGES_START_TYPE,
  CALCULATE_ORDER_CHARGES_SUCCESSFUL_TYPE,
  CALCULATE_ORDER_CHARGES_FAILED_TYPE,
  UNSELECT_ALL_ORDERS_ROW_TYPE,
  COMPLETE_ORDER_TO_DRIVER_START_TYPE,
  COMPLETE_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
  COMPLETE_ORDER_TO_DRIVER_FAILED_TYPE,
  CANCEL_ORDER_START_TYPE,
  CANCEL_ORDER_FAILED_TYPE,
  CANCEL_ORDER_SUCCESSFUL_TYPE,
  CREATE_ORDER_START_TYPE,
  CREATE_ORDER_SUCCESSFUL_TYPE,
  CREATE_ORDER_FAILED_TYPE,
  FETCH_ORDERS_START_TYPE,
  FETCH_ORDERS_SUCCESSFUL_TYPE,
  FETCH_ORDERS_FAILED_TYPE,
  FETCH_ORDER_MESSAGES_START_TYPE,
  FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE,
  FETCH_ORDER_MESSAGES_FAILED_TYPE,
  POST_ORDER_MESSAGE_START_TYPE,
  POST_ORDER_MESSAGE_SUCCESSFUL_TYPE,
  POST_ORDER_MESSAGE_FAILED_TYPE,
  CLEAR_SEARCH_ORDER_TYPE,
  DELETE_ORDER_ITEM_START_TYPE,
  DELETE_ORDER_ITEM_SUCCESSFUL_TYPE,
  DELETE_ORDER_ITEM_FAILED_TYPE,
  DISPATCH_ORDER_TO_DRIVER_START_TYPE,
  DISPATCH_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
  DISPATCH_ORDER_TO_DRIVER_FAILED_TYPE,
  SELECT_ORDER_ROW_TYPE,
  UPDATE_ORDER_START_TYPE,
  UPDATE_ORDER_SUCCESSFUL_TYPE,
  UPDATE_ORDER_FAILED_TYPE,
  RE_ORDER_START_TYPE,
  RE_ORDER_SUCCESSFUL_TYPE,
  RE_ORDER_FAILED_TYPE,
  SEARCH_ORDERS_START_TYPE,
  SEARCH_ORDERS_SUCCESSFUL_TYPE,
  SEARCH_ORDERS_FAILED_TYPE,
};
