import { apiInstance } from "../../config";

const ADD_ADDRESS_START_TYPE = "ADD_ADDRESS_START";
const ADD_ADDRESS_SUCCESSFUL_TYPE = "ADD_ADDRESS_SUCCESSFUL";
const ADD_ADDRESS_FAILED_TYPE = "ADD_ADDRESS_FAILED";

/**
 *
 */
function actionAddAddress(address: any, accountId: string) {
  return {
    types: [
      ADD_ADDRESS_START_TYPE,
      ADD_ADDRESS_SUCCESSFUL_TYPE,
      ADD_ADDRESS_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/address`, {
      ...address,
      userId: accountId,
    }),
  };
}

const EDIT_ADDRESS_START_TYPE = "EDIT_ADDRESS_START";
const EDIT_ADDRESS_SUCCESSFUL_TYPE = "EDIT_ADDRESS_SUCCESSFUL";
const EDIT_ADDRESS_FAILED_TYPE = "EDIT_ADDRESS_FAILED";

/**
 *
 */
function actionEditAddress(address: any, accountId: string) {
  return {
    types: [
      EDIT_ADDRESS_START_TYPE,
      EDIT_ADDRESS_SUCCESSFUL_TYPE,
      EDIT_ADDRESS_FAILED_TYPE,
    ],
    promise: apiInstance.put(`/address`, {
      ...address,
    }),
    payload: {
      accountId,
    },
  };
}

export {
  actionAddAddress,
  actionEditAddress,
  ADD_ADDRESS_START_TYPE,
  ADD_ADDRESS_SUCCESSFUL_TYPE,
  ADD_ADDRESS_FAILED_TYPE,
  EDIT_ADDRESS_START_TYPE,
  EDIT_ADDRESS_SUCCESSFUL_TYPE,
  EDIT_ADDRESS_FAILED_TYPE,
};
