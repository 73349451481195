import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      alignSelf: "stretch",
      display: "flex",
      boxSizing: "border-box",
    },
    table: {
      // temporary right-to-left patch, waiting for
      // https://github.com/bvaughn/react-virtualized/issues/454
      "& .ReactVirtualized__Table__headerRow": {
        flip: false,
        paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
        backgroundColor: "white",
      },
      "& .ReactVirtualized__Table__row": {
        backgroundColor: "white",
      },
    },
    tableRow: {
      cursor: "pointer",
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
    },
    tableHeaderCell: {},
    noClick: {
      cursor: "initial",
    },
    emptyTableReportCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    emptyTableReportCardHeader: {
      backgroundColor: "white",
    },
  });

export default styles;
