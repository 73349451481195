import { List, fromJS } from "immutable";
import isEmpty from "lodash.isempty";

import {
  SEARCH_PRODUCTS_START_TYPE,
  SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
  SEARCH_PRODUCTS_FAILED_TYPE,
  CREATE_PRODUCT_START_TYPE,
  CREATE_PRODUCT_SUCCESSFUL_TYPE,
  CREATE_PRODUCT_FAILED_TYPE,
  EDIT_PRODUCT_START_TYPE,
  EDIT_PRODUCT_SUCCESSFUL_TYPE,
  EDIT_PRODUCT_FAILED_TYPE,
  DELETE_PRODUCT_START_TYPE,
  DELETE_PRODUCT_SUCCESSFUL_TYPE,
  DELETE_PRODUCT_FAILED_TYPE,
} from "./actions";
import {
  PRODUCTS_FETCH_START_TYPE,
  PRODUCTS_FETCH_SUCCESSFUL_TYPE,
  PRODUCTS_FETCH_FAILED_TYPE,
  CLEAR_SEARCH_PRODUCTS_TYPE,
} from "../../views/Products/actions";

interface ProductsState {
  data: List<any>;
  searchedData: List<any>;
  isSearchingProducts: boolean;
  isFetchingProducts: boolean;
  isCreatingProduct: boolean;
  isEditingProduct: boolean;
  isDeletingProduct: boolean;
}

const initialProductsState: ProductsState = {
  data: List<any>(),
  searchedData: List<any>(),
  isFetchingProducts: false,
  isSearchingProducts: false,
  isCreatingProduct: false,
  isEditingProduct: false,
  isDeletingProduct: false,
};

// @ts-ignore
function reducer(state = initialProductsState, { type, payload }) {
  switch (type) {
    case SEARCH_PRODUCTS_START_TYPE: {
      return {
        ...state,
        isSearchingProducts: true,
      };
    }

    case SEARCH_PRODUCTS_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.searchedData = fromJS(responseData);
      }

      return {
        ...state,
        isSearchingProducts: false,
      };
    }

    case SEARCH_PRODUCTS_FAILED_TYPE: {
      return {
        ...state,
        isSearchingProducts: false,
      };
    }

    case PRODUCTS_FETCH_START_TYPE: {
      return {
        ...state,
        isFetchingProducts: true,
      };
    }

    case PRODUCTS_FETCH_SUCCESSFUL_TYPE: {
      const { data: responseData } = payload;

      if (!isEmpty(responseData)) {
        state.data = fromJS(responseData);
      }

      return {
        ...state,
        isFetchingProducts: false,
      };
    }

    case PRODUCTS_FETCH_FAILED_TYPE: {
      return {
        ...state,
        isFetchingProducts: false,
      };
    }

    case CLEAR_SEARCH_PRODUCTS_TYPE: {
      state.searchedData = List();

      return {
        ...state,
      };
    }
    case CREATE_PRODUCT_START_TYPE: {
      return {
        ...state,
        isCreatingProduct: true,
      };
    }

    case CREATE_PRODUCT_SUCCESSFUL_TYPE: {
      const { data: newProduct } = payload;
      return {
        ...state,
        isCreatingProduct: false,
        data: state.data.push(fromJS(newProduct)),
      };
    }

    case CREATE_PRODUCT_FAILED_TYPE: {
      return {
        ...state,
        isCreatingProduct: false,
      };
    }

    case EDIT_PRODUCT_START_TYPE: {
      return {
        ...state,
        isEditingProduct: true,
      };
    }

    case EDIT_PRODUCT_SUCCESSFUL_TYPE: {
      const { data: updatedProduct } = payload;
      return {
        ...state,
        isEditingProduct: false,
        data: state.data.map(product => 
          product.get('id') === updatedProduct.id ? fromJS(updatedProduct) : product
        ),
      };
    }

    case EDIT_PRODUCT_FAILED_TYPE: {
      return {
        ...state,
        isEditingProduct: false,
      };
    }

    case DELETE_PRODUCT_START_TYPE: {
      return {
        ...state,
        isDeletingProduct: true,
      };
    }

    case DELETE_PRODUCT_SUCCESSFUL_TYPE: {
      const { id: deletedProductId } = payload;
      return {
        ...state,
        isDeletingProduct: false,
        data: state.data.filter(product => product.get('id') !== deletedProductId),
      };
    }

    case DELETE_PRODUCT_FAILED_TYPE: {
      return {
        ...state,
        isDeletingProduct: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
