import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { ErrorSnackbarContext } from "../../App";
import { ORDER_FORM_TYPE } from "../../common/constants";
import { actionSetAccountNotes } from "../../store/Accounts/actions";
import { actionCreateOrder } from "./actions";
import Order from "./Order";

function CreateOrder() {
  const { toggleAlerts } = useContext(ErrorSnackbarContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = {
    tax: "",
    type: "call",
    category: "regular",
    address: null,
    customer: null,
    customerNotes: null,
    subtotal: "",
    grandTotal: "",
    deliveryTotal: "",
    products: [],
    paymentMethod: null,
    deliveryCharge: "",
    paymentSurcharge: "",
    distanceSurcharge: "",
    varietyOrder: null,
    varietyItems: "",
    discountCode: null,
    receivedAt: new Date(),
    varietySurcharge: "",
    surchargeTotal: "",
    senderAccount: null,
    senderAddress: null,
    billAccount: null,
    billAddress: null,
    extrasSurcharge: "",
    bothStoreSurcharge: "",
    externalId: null,
    company: null,
  };

  function onSubmit(values: any) {
    dispatch(actionCreateOrder(values))
      // @ts-ignore
      .then(
        () => {
          dispatch(actionSetAccountNotes(values.userId, values.customerNotes));
          toggleAlerts("Successfully created the order.");

          history.replace(`/`);
        },
        () => {
          toggleAlerts("Error occurred while creating the order.", true);
        }
      );
  }

  return (
    <Order
      orderFormType={ORDER_FORM_TYPE.Create}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  );
}

export default CreateOrder;
