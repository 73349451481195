import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { SignIn } from "./Auth";
import Home from "./views/Home";

function RootRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/signin" component={SignIn} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export { RootRouter };
