import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import AccountsReducer from "./Accounts/reducer";
import ProductsReducer from "./Products/reducer";

import AuthReducer from "../Auth/reducer";
import OrdersReducer from "../views/Orders/reducer";
import ReportsReducer from "../views/Reports/reducer";
import DispatchReducer from "../views/Dispatch/reducer";
import PaymentMethodsReducer from "./PaymentMethods/reducer";

import { promiseMiddleware } from "./promiseMiddleware";

// TODO: Add prod handling
const store = createStore(
  combineReducers({
    auth: AuthReducer,
    orders: OrdersReducer,
    accounts: AccountsReducer,
    products: ProductsReducer,
    paymentMethods: PaymentMethodsReducer,
    reports: ReportsReducer,
    dispatch: DispatchReducer,
  }),
  composeWithDevTools(applyMiddleware(promiseMiddleware))
);

export { store };
