import { apiInstance } from "../../config";

const FETCH_DISPATCH_DRIVERS_START_TYPE = "FETCH_DISPATCH_DRIVERS_START";
const FETCH_DISPATCH_DRIVERS_SUCCESSFUL_TYPE =
  "FETCH_DISPATCH_DRIVERS_SUCCESSFUL";
const FETCH_DISPATCH_DRIVERS_FAILED_TYPE = "FETCH_DISPATCH_DRIVERS_FAILED";

/**
 *
 */
function actionFetchDispatchDrivers() {
  return {
    types: [
      FETCH_DISPATCH_DRIVERS_START_TYPE,
      FETCH_DISPATCH_DRIVERS_SUCCESSFUL_TYPE,
      FETCH_DISPATCH_DRIVERS_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/admin/dispatch/drivers`),
  };
}

const FETCH_ORDER_MESSAGES_START_TYPE = "FETCH_ORDER_MESSAGES_START";
const FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE = "FETCH_ORDER_MESSAGES_SUCCESSFUL";
const FETCH_ORDER_MESSAGES_FAILED_TYPE = "FETCH_ORDER_MESSAGES_FAILED";

/**
 *
 */
function actionFetchMessages(orderId: string) {
  return {
    types: [
      FETCH_ORDER_MESSAGES_START_TYPE,
      FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE,
      FETCH_ORDER_MESSAGES_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/driver/dispatch/messages`, {
      params: {
        orderId,
      },
    }),
  };
}

const REASSIGN_ORDER_TO_DRIVER_START_TYPE = "REASSIGN_ORDER_TO_DRIVER_START";
const REASSIGN_ORDER_TO_DRIVER_SUCCESSFUL_TYPE =
  "REASSIGN_ORDER_TO_DRIVER_SUCCESSFUL";
const REASSIGN_ORDER_TO_DRIVER_FAILED_TYPE = "REASSIGN_ORDER_TO_DRIVER_FAILED";

/**
 *
 */
function actionReassignOrderToDriver(orderId: string, driverId: string) {
  return {
    types: [
      REASSIGN_ORDER_TO_DRIVER_START_TYPE,
      REASSIGN_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
      REASSIGN_ORDER_TO_DRIVER_FAILED_TYPE,
    ],
    promise: apiInstance.patch(`/admin/dispatch/reassign`, {
      orderId,
      driverId,
    }),
  };
}

const MARK_ORDER_COMPLETE_START_TYPE = "MARK_ORDER_COMPLETE_START";
const MARK_ORDER_COMPLETE_SUCCESSFUL_TYPE = "MARK_ORDER_COMPLETE_SUCCESSFUL";
const MARK_ORDER_COMPLETE_FAILED_TYPE = "MARK_ORDER_COMPLETE_FAILED";

/**
 *
 */
function actionMarkOrderComplete(
  orderId: string,
  driverId: string,
  deliveredAt?: string | Date
) {
  return {
    types: [
      MARK_ORDER_COMPLETE_START_TYPE,
      MARK_ORDER_COMPLETE_SUCCESSFUL_TYPE,
      MARK_ORDER_COMPLETE_FAILED_TYPE,
    ],
    promise: apiInstance.patch(`/driver/dispatch/complete_order`, {
      orderId,
      driverId,
      deliveredAt,
    }),
  };
}

const UPDATE_DRIVER_AVAILABILITY_START_TYPE =
  "UPDATE_DRIVER_AVAILABILITY_START";
const UPDATE_DRIVER_AVAILABILITY_SUCCESSFUL_TYPE =
  "UPDATE_DRIVER_AVAILABILITY_SUCCESSFUL";
const UPDATE_DRIVER_AVAILABILITY_FAILED_TYPE =
  "UPDATE_DRIVER_AVAILABILITY_FAILED";

/**
 *
 */
function actionUpdateDriverAvailability(driverId: string, isDriving: boolean) {
  return {
    types: [
      UPDATE_DRIVER_AVAILABILITY_START_TYPE,
      UPDATE_DRIVER_AVAILABILITY_SUCCESSFUL_TYPE,
      UPDATE_DRIVER_AVAILABILITY_FAILED_TYPE,
    ],
    promise: apiInstance.patch(`/admin/dispatch/driver`, {
      driverId,
      isDriving,
    }),
  };
}

const SELECT_DRIVER_TYPE = "SELECT_DRIVER";

/**
 *
 */
function actionSelectDriver(driverId: number) {
  return {
    type: SELECT_DRIVER_TYPE,
    payload: {
      driverId,
    },
  };
}

const UNSELECT_ALL_DRIVERS_TYPE = "UNSELECT_ALL_DRIVERS";

/**
 *
 */
function actionUnSelectAllDrivers() {
  return {
    type: UNSELECT_ALL_DRIVERS_TYPE,
  };
}

export {
  actionMarkOrderComplete,
  actionFetchDispatchDrivers,
  actionReassignOrderToDriver,
  actionFetchMessages,
  actionUpdateDriverAvailability,
  actionSelectDriver,
  actionUnSelectAllDrivers,
  FETCH_ORDER_MESSAGES_START_TYPE,
  FETCH_ORDER_MESSAGES_SUCCESSFUL_TYPE,
  FETCH_ORDER_MESSAGES_FAILED_TYPE,
  FETCH_DISPATCH_DRIVERS_START_TYPE,
  FETCH_DISPATCH_DRIVERS_SUCCESSFUL_TYPE,
  FETCH_DISPATCH_DRIVERS_FAILED_TYPE,
  UPDATE_DRIVER_AVAILABILITY_START_TYPE,
  UPDATE_DRIVER_AVAILABILITY_SUCCESSFUL_TYPE,
  UPDATE_DRIVER_AVAILABILITY_FAILED_TYPE,
  REASSIGN_ORDER_TO_DRIVER_START_TYPE,
  REASSIGN_ORDER_TO_DRIVER_SUCCESSFUL_TYPE,
  REASSIGN_ORDER_TO_DRIVER_FAILED_TYPE,
  SELECT_DRIVER_TYPE,
  UNSELECT_ALL_DRIVERS_TYPE,
  MARK_ORDER_COMPLETE_START_TYPE,
  MARK_ORDER_COMPLETE_SUCCESSFUL_TYPE,
  MARK_ORDER_COMPLETE_FAILED_TYPE,
};
