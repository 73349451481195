import { createStyles, Theme } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    orderItems: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    orderItemsCardAction: {
      padding: theme.spacing(2),
      justifyContent: "space-between",
    },
    orderProductItem: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    orderHeaderButton: {
      marginLeft: theme.spacing(1),
    },
    cancelDialog: {
      width: "350px",
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    mainCard: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
    submitButtons: {},
    submitCalculateButtons: {
      marginRight: theme.spacing(1),
    },
  });

export default styles;
