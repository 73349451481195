import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";

import { ErrorSnackbarContext } from "../../../App";
import { actionCreateAccount } from "../../../store/Accounts/actions";

interface IAddAccountProps {
  show: boolean;
  onlyCustomer: boolean;
  onClose: () => void;
}

const AddAccountSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  address2: yup.string().nullable(),
  buzzerCode: yup.string().nullable(),
  phoneExt: yup.string().nullable(),
  email: yup.string().nullable(),
  password: yup.string().nullable(),
  address1: yup.string().required("Required"),
  city: yup.string().required("Required"),
  province: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  notes: yup.string().nullable(),
});

function AddAccount({ show, onClose, onlyCustomer }: IAddAccountProps) {
  const dispatch = useDispatch();
  const [isCustomer, setIsCustomer] = useState(false);

  return (
    <Dialog open={show} onClose={onClose}>
      <ErrorSnackbarContext.Consumer>
        {({ toggleAlerts }) => (
          <Formik
            key="AddAccount"
            validationSchema={AddAccountSchema}
            validateOnChange={false}
            enableReinitialize
            initialValues={{
              admin: false,
              driver: false,
              firstName: "",
              lastName: "",
              address1: "",
              address2: "",
              buzzerCode: "",
              email: "",
              password: "",
              city: "",
              postalCode: "",
              province: "",
              phone: "",
              phoneExt: "",
              notes: "",
            }}
            onSubmit={async (values) => {
              await dispatch(actionCreateAccount(values))
                // @ts-ignore
                .then(
                  () => {
                    toggleAlerts("Successfully added the user account.", false);
                    onClose();
                  },
                  () => {
                    toggleAlerts(
                      "Error occurred while creating the user account.",
                      true
                    );
                  }
                );
            }}
          >
            {({
              errors,
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => {
              return (
                <>
                  <DialogTitle>Add a user account</DialogTitle>

                  <Divider />

                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Email"
                          placeholder="Email"
                          variant="outlined"
                          name="email"
                          error={!!errors.email}
                          helperText={errors.email}
                          value={values.email}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Password"
                          placeholder="Password"
                          variant="outlined"
                          name="password"
                          type="password"
                          error={!!errors.password}
                          helperText={errors.password}
                          value={values.password}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="First Name"
                          placeholder="First Name"
                          variant="outlined"
                          name="firstName"
                          error={!!errors.firstName}
                          helperText={errors.firstName}
                          value={values.firstName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Last Name"
                          placeholder="Last Name"
                          variant="outlined"
                          name="lastName"
                          error={!!errors.lastName}
                          helperText={errors.lastName}
                          value={values.lastName}
                          onChange={handleChange}
                        />
                      </Grid>
                      
                      {onlyCustomer ? null : (
                        <Grid item lg={12}>
                        <FormControlLabel
                          label="Customer"
                          control={
                            <Checkbox
                              onChange={(event) => {
                                  if (event.currentTarget.checked) {
                                    values.admin = false;
                                    values.driver = false;
                                    setIsCustomer(true)
                                  } else {
                                    setIsCustomer(false)
                                  }
                              }}
                              name="customer"
                            />
                          }
                        />

                          <FormControlLabel
                            label="Admin"
                            control={
                              <Checkbox
                                checked={values.admin}
                                onChange={handleChange}
                                name="admin"
                                disabled={isCustomer}
                              />
                            }
                          />

                          <FormControlLabel
                            label="Driver"
                            control={
                              <Checkbox
                                checked={values.driver}
                                onChange={handleChange}
                                name="driver"
                                disabled={isCustomer}
                              />
                            }
                          />
                        </Grid>
                      )}
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Address 1"
                          placeholder="Address 1"
                          variant="outlined"
                          name="address1"
                          error={!!errors.address1}
                          helperText={errors.address1}
                          value={values.address1}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Address 2"
                          placeholder="Address 2"
                          variant="outlined"
                          name="address2"
                          value={values.address2}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          label="City"
                          size="small"
                          placeholder="City"
                          variant="outlined"
                          name="city"
                          value={values.city}
                          error={!!errors.city}
                          helperText={errors.city}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Postal Code"
                          variant="outlined"
                          name="postalCode"
                          placeholder="Postal Code"
                          inputProps={{
                            maxLength: 6,
                          }}
                          error={!!errors.postalCode}
                          helperText={errors.postalCode}
                          value={values.postalCode}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          name="province"
                          label="Province"
                          variant="outlined"
                          placeholder="Province"
                          error={!!errors.province}
                          helperText={errors.province}
                          value={values.province}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Phone"
                          placeholder="Phone"
                          variant="outlined"
                          name="phone"
                          error={!!errors.phone}
                          helperText={errors.phone}
                          value={values.phone}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          label="Phone Ext"
                          size="small"
                          placeholder="Phone Ext"
                          variant="outlined"
                          name="phoneExt"
                          value={values.phoneExt}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          label="Buzzer code"
                          size="small"
                          placeholder="Buzzer code"
                          variant="outlined"
                          name="buzzerCode"
                          value={values.buzzerCode}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          label="Notes"
                          placeholder="Notes"
                          variant="outlined"
                          name="notes"
                          value={values.notes}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <Divider />

                  <DialogActions>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => onClose()}
                    >
                      Close
                    </Button>

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </>
              );
            }}
          </Formik>
        )}
      </ErrorSnackbarContext.Consumer>
    </Dialog>
  );
}

export default AddAccount;
