import {
  Avatar,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { PeopleAlt as UsersIcon } from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionSelectDriver } from "../actions";
import styles from "../styles";

interface IDriversAvailableProps extends WithStyles<typeof styles> {}

function DriversAvailable({ classes }: IDriversAvailableProps) {
  const dispatch = useDispatch();

  const availableDrivers = useSelector((state: any) => {
    const driversList = state.dispatch.driversData;

    if (driversList.size) {
      return driversList.filter(
        (account: any) => account.get("email") && account.get("isDriving")
      );
    }

    return driversList;
  });

  if (!availableDrivers.size) {
    return null;
  }

  return (
    <Card square className={classes.driversCard}>
      <CardHeader
        subheader="Drivers Available Today"
        className={classes.driversCardHeader}
      />

      <Divider light />

      <List className={classes.listRoot}>
        {availableDrivers.map((driver: any, index: number) => (
          <React.Fragment key={driver.get("id")}>
            <ListItem alignItems="flex-start" >
              <ListItemAvatar>
                <Avatar>
                  <UsersIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={`${driver.get("email")} - ${driver.get(
                  "firstName"
                )} ${driver.get("lastName")}`}
                secondary={`Delivering ${driver.get("count")} orders`}
              />

              <ListItemSecondaryAction>
                <FormControlLabel
                  control={
                    <Checkbox
                      key={`driver-${index}`}
                      color="primary"
                      checked={driver.get("selected")}
                      onChange={() =>
                        dispatch(actionSelectDriver(driver.get("id")))
                      }
                    />
                  }
                  label="Select To Filter"
                />
              </ListItemSecondaryAction>
            </ListItem>

            {index < availableDrivers.size - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </React.Fragment>
        ))}
      </List>
    </Card>
  );
}

export default withStyles(styles)(DriversAvailable);
