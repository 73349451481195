import { COMPANY } from "../../common/constants";
import { apiInstance } from "../../config";

const SEARCH_PRODUCTS_START_TYPE = "SEARCH_PRODUCTS_START";
const SEARCH_PRODUCTS_SUCCESSFUL_TYPE = "SEARCH_PRODUCTS_SUCCESSFUL";
const SEARCH_PRODUCTS_FAILED_TYPE = "SEARCH_PRODUCTS_FAILED";

const CREATE_PRODUCT_START_TYPE = "CREATE_PRODUCT_START";
const CREATE_PRODUCT_SUCCESSFUL_TYPE = "CREATE_PRODUCT_SUCCESSFUL";
const CREATE_PRODUCT_FAILED_TYPE = "CREATE_PRODUCT_FAILED";

const EDIT_PRODUCT_START_TYPE = "EDIT_PRODUCT_START";
const EDIT_PRODUCT_SUCCESSFUL_TYPE = "EDIT_PRODUCT_SUCCESSFUL";
const EDIT_PRODUCT_FAILED_TYPE = "EDIT_PRODUCT_FAILED";

const DELETE_PRODUCT_START_TYPE = "DELETE_PRODUCT_START";
const DELETE_PRODUCT_SUCCESSFUL_TYPE = "DELETE_PRODUCT_SUCCESSFUL";
const DELETE_PRODUCT_FAILED_TYPE = "DELETE_PRODUCT_FAILED";
/**
 *
 */
function actionSearchProducts(searchTerm?: string, company?: COMPANY) {
  return {
    types: [
      SEARCH_PRODUCTS_START_TYPE,
      SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
      SEARCH_PRODUCTS_FAILED_TYPE,
    ],
    promise: apiInstance.get("/search/products", {
      params: {
        searchTerm,
        company,
      },
    }),
  };
}

function actionCreateProduct(product: any) {
  return {
    types: [
      CREATE_PRODUCT_START_TYPE,
      CREATE_PRODUCT_SUCCESSFUL_TYPE,
      CREATE_PRODUCT_FAILED_TYPE,
    ],
    promise: apiInstance.post("/admin/product", {
      params: {
        ...product
      }
    })
  }
}

function actionEditProduct(product: any) {
  return {
    types: [
      EDIT_PRODUCT_START_TYPE,
      EDIT_PRODUCT_SUCCESSFUL_TYPE,
      EDIT_PRODUCT_FAILED_TYPE,
    ],
    promise: apiInstance.put(`/admin/product/${product.id}`, {
      params: {
        ...product
      }
    })
  }
}

function actionDeleteProduct(product: any) {
  return {
    types: [
      DELETE_PRODUCT_START_TYPE,
      DELETE_PRODUCT_SUCCESSFUL_TYPE,
      DELETE_PRODUCT_FAILED_TYPE,
    ],
    promise: apiInstance.delete(`/admin/product/${product.id}`)
  }
}

export {
  actionSearchProducts,
  actionCreateProduct,
  actionEditProduct,
  actionDeleteProduct,
  SEARCH_PRODUCTS_START_TYPE,
  SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
  SEARCH_PRODUCTS_FAILED_TYPE,
  CREATE_PRODUCT_START_TYPE,
  CREATE_PRODUCT_SUCCESSFUL_TYPE,
  CREATE_PRODUCT_FAILED_TYPE,
  DELETE_PRODUCT_START_TYPE,
  DELETE_PRODUCT_SUCCESSFUL_TYPE,
  DELETE_PRODUCT_FAILED_TYPE,
  EDIT_PRODUCT_START_TYPE,
  EDIT_PRODUCT_SUCCESSFUL_TYPE,
  EDIT_PRODUCT_FAILED_TYPE,
};
