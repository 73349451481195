import React from "react";
import clsx from "clsx";
import {
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";

// Styles
import { useStyles } from "./styles";

interface ListItemLinkProps extends ListItemProps {
  icon?: any;
  inset?: boolean;
  text: string;
  to: string;
}

/**
 * Material UI list item link via React Router
 *
 * Taken from: https://material-ui.com/guides/composition/#list
 *
 * @param props - React props
 */
const ListItemLink = (props: ListItemLinkProps) => {
  const { icon, text, to } = props;

  const inset = props.inset ? true : !icon;

  const classes = useStyles();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        // @ts-ignore
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        className={clsx(classes.item, classes.itemCategory)}
        // @ts-ignore
        component={renderLink}
      >
        {icon ? (
          <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText
          className={classes.itemText}
          inset={inset}
          primary={text}
        />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
