const ProductsTableColumns = [
  {
    dataKey: "name",
    label: "Name",
    width: 550,
  },
  {
    dataKey: "company",
    label: "Parent Company",
    width: 230,
  },
  {
    dataKey: "type",
    label: "Type",
    width: 130,
  },
  {
    dataKey: "size",
    label: "Size",
    width: 200,
  },
  {
    dataKey: "producer",
    label: "Producer",
    width: 400,
  },
];

export { ProductsTableColumns };
