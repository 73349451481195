import { apiInstance } from "../../config";

const PRODUCTS_FETCH_START_TYPE = "PRODUCTS_FETCH_START";
const PRODUCTS_FETCH_SUCCESSFUL_TYPE = "PRODUCTS_FETCH_SUCCESSFUL";
const PRODUCTS_FETCH_FAILED_TYPE = "PRODUCTS_FETCH_FAILED";

/**
 *
 */
function actionFetchAllProducts() {
  return {
    types: [
      PRODUCTS_FETCH_START_TYPE,
      PRODUCTS_FETCH_SUCCESSFUL_TYPE,
      PRODUCTS_FETCH_FAILED_TYPE,
    ],
    promise: apiInstance.get("/product/all"),
  };
}

const CLEAR_SEARCH_PRODUCTS_TYPE = "CLEAR_SEARCH_PRODUCTS";

/**
 *
 */
function actionClearSearchProducts() {
  return {
    type: CLEAR_SEARCH_PRODUCTS_TYPE,
  };
}

export {
  actionClearSearchProducts,
  actionFetchAllProducts,
  CLEAR_SEARCH_PRODUCTS_TYPE,
  PRODUCTS_FETCH_START_TYPE,
  PRODUCTS_FETCH_SUCCESSFUL_TYPE,
  PRODUCTS_FETCH_FAILED_TYPE,
};
