import React, { useEffect, forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { TextField, Select, MenuItem, Grid, Button, withStyles, WithStyles } from "@material-ui/core";
import * as Yup from "yup";
import { actionCreateProduct, actionEditProduct } from "../../store/Products/actions";

const styles = (theme: any) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  formActions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

interface ProductProps extends WithStyles<typeof styles> {
  product?: {
    id?: string;
    product_name: string;
    company: string;
    type?: string;
    size?: string;
    producer?: string;
  };
  closeDialog: () => void;
}

const ProductSchema = Yup.object().shape({
  id: Yup.string().nullable(),
  product_name: Yup.string().required("Required"),
  company: Yup.string().required("Required"),
  type: Yup.string().nullable(),
  size: Yup.string().nullable(),
  producer: Yup.string().nullable(),
});

interface FormValues {
  id?: string;
  product_name: string;
  company: string;
  type?: string;
  size?: string;
  producer?: string;
}

interface FormHandle {
  handleSubmit: () => void;
}

const CreateOrEditProduct = forwardRef<FormHandle, ProductProps>(({ product, closeDialog, classes }, ref) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<FormValues>({
    id: product?.id || "",
    product_name: product?.product_name || "",
    company: product?.company || "",
    type: product?.type || "",
    size: product?.size || "",
    producer: product?.producer || "",
  });

  useEffect(() => {
    if (product) {
      setInitialValues({
        id: product.id,
        product_name: product.product_name,
        company: product.company,
        type: product.type,
        size: product.size,
        producer: product.producer,
      });
    }
  }, [product]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      (ref as React.MutableRefObject<any>)?.current.submitForm();
    },
  }));

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      if (product) {
        await dispatch(actionEditProduct(values));
      } else {
        await dispatch(actionCreateProduct(values));
      }
      closeDialog();
    } catch (error) {
      console.error("Error saving product", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ProductSchema}
      onSubmit={handleSubmit}
      enableReinitialize
      innerRef={ref as any}
    >
      {({ isSubmitting, values, handleChange }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.formField}>
              <Field
                name="product_name"
                as={TextField}
                label="Product Name"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <Field
                name="company"
                as={Select}
                label="Company"
                fullWidth
                required
                value={values.company}
                onChange={handleChange}
              >
                <MenuItem value="the-beer-store">The Beer Store</MenuItem>
                <MenuItem value="lcbo">LCBO</MenuItem>
              </Field>
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <Field
                name="type"
                as={TextField}
                label="Type"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <Field
                name="size"
                as={TextField}
                label="Size"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className={classes.formField}>
              <Field
                name="producer"
                as={TextField}
                label="Producer"
                fullWidth
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});

export default withStyles(styles)(CreateOrEditProduct);